import { findFirstNonNil } from '@arzt-direkt/wfa-generic-utils'

import { wfaFormDefault } from '../joi/wfa-form.joi'
import { wfaFormStatuses } from '../joi/wfa-form-status.joi'
import { WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { WfaFormStatus } from './../interfaces-autogen-from-joi/wfa-form-status'
import version from './../version.json'
import { convertToWfaFormId } from './convert-to-wfa-form-id'
import { wfaFormIdsToString } from './wfa-form-ids-to-string'

const DEFAULT_STATUS = 'live'
const DEFAULT_TITLE = { de: 'Unbekanntes Formular' }

export const repairDummyIds = {
  praxisId: '677d3149633ed1dc7ea0590c',
  wfaFormId: '677d31935df8e25b17be5024',
}

function resolveFormId(form: Partial<WfaForm>): string {
  return findFirstNonNil([
    form._id,
    form.wfaFormVersionId,
    repairDummyIds.wfaFormId,
  ]).toString()
}

function resolvePraxisId(
  form: Partial<WfaForm>,
  defaultForm: Partial<WfaForm>,
): string {
  return findFirstNonNil([
    form.praxisId,
    form.instance,
    defaultForm.praxisId,
    repairDummyIds.praxisId,
  ]).toString()
}

function resolveStatus(
  status: string | undefined,
  defaultStatus: string,
): WfaFormStatus {
  const rawStatus = findFirstNonNil([status, defaultStatus, DEFAULT_STATUS])
  return wfaFormStatuses.includes(rawStatus as WfaFormStatus)
    ? (rawStatus as WfaFormStatus)
    : wfaFormDefault.status
}

export function repairWfaForm(
  inputForm: Partial<WfaForm>,
  defaultForm: Partial<WfaForm> = wfaFormDefault,
): WfaForm {
  const wfaForm = { ...inputForm }
  const formVersionId = resolveFormId(wfaForm)
  const praxisId = resolvePraxisId(wfaForm, defaultForm)
  const status = resolveStatus(
    wfaForm?.status,
    defaultForm.status as WfaFormStatus,
  )

  const title = findFirstNonNil([
    wfaForm?.title,
    defaultForm.title,
    DEFAULT_TITLE,
  ])

  const wfaVersion = findFirstNonNil([
    wfaForm?.wfaVersion,
    defaultForm.wfaVersion,
    version.wfaVersion,
  ])

  const wfaFormId =
    typeof wfaForm.wfaFormId === 'string'
      ? convertToWfaFormId(wfaForm.wfaFormId)
      : convertToWfaFormId('')

  return wfaFormIdsToString({
    ...wfaForm,
    _id: formVersionId,
    wfaFormId,
    wfaFormVersionId: formVersionId,
    instance: praxisId,
    praxisId,
    status,
    title,
    wfaVersion,
  })
}
