import { Operator } from '../types/operator'

/**
 * Composes multiple functions from left to right, creating a pipeline of operations.
 * Each function's output becomes the input for the next function in the sequence.
 *
 * @example
 * const processUser = flow(
 *   (id: number) => fetchUser(id),         // id -> User
 *   (user: User) => validateUser(user),    // User -> ValidUser
 *   (user: ValidUser) => formatUser(user)  // ValidUser -> FormattedUser
 * )
 *
 * // Usage: const result = processUser(123)
 *
 * @template T1 - Type of initial input
 * @template T2 - Type of first transformation
 * @template T3 - Type of second transformation
 * @template T4 - Type of third transformation
 * @param functions - Series of functions to be composed
 * @returns A function that accepts the initial input and returns the final transformed value
 * @throws If any function in the pipeline throws an error, it will propagate up
 */
export function flow<T1, T2>(f1: (x: T1) => T2): (x: T1) => T2
export function flow<T1, T2, T3>(
  f1: (x: T1) => T2,
  f2: (x: T2) => T3,
): (x: T1) => T3
export function flow<T1, T2, T3, T4>(
  f1: (x: T1) => T2,
  f2: (x: T2) => T3,
  f3: (x: T3) => T4,
): (x: T1) => T4
export function flow<T>(...functions: Operator<T>[]): Operator<T> {
  return (initialValue: T) =>
    functions.reduce((result, fn) => fn(result), initialValue)
}
