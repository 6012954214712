import { AuthService } from "@a-d/auth/auth.service"
import { Announcement, GlobalSetting } from "@a-d/entities/Global-Setting.entity"
import { AdLoggerService } from '@a-d/logging/ad-logger.service'
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { CanActivate } from "@angular/router"
import { environment } from "@env/environment"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import { Observable, of, Subject } from "rxjs"
import { catchError, mergeMap } from "rxjs/operators"
import { NotificationService } from '../../../../../lib/src/lib/notifications/notification.service'

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {
  globalSettings: GlobalSetting
  announcementDefault: Announcement = { announcementTextDe: '', announcementTextEn: '', showAnnouncement: false }
  globalSettingsDefault: GlobalSetting = { announcement: this.announcementDefault }
  globalSettingsChanged: Subject<any> = new Subject()
  constructor(
    private adLoggerService: AdLoggerService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService
  ) { }

  /** this function is only called once(ish) by the GlobalSettingsGuard.
   *  to use globalSettings in other components, don't call this function again,
   * just use this.globalSettingsService.globalSettings directly.
   */
  loadGlobalSettings(): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          return this.httpClient.get(environment.url + '/api/global-settings/')
        }
        ),
        catchError((error) => {
          this.adLoggerService.error(error)
          this.globalSettings = this.globalSettingsDefault
          return of(null)
        }),
        mergeMap((response: any) => {
          this.globalSettings = response.globalSettings
            ? response.globalSettings
            : this.globalSettingsDefault
          return of(null)
        }
        )
      )
  }

  setGlobalSettings(globalSettings: GlobalSetting): Observable<any> {
    return of(null)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => {
          const user = this.authService.user
          const query = { globalSettings, user }
          return this.httpClient.post(environment.url + '/api/global-settings/', query)
        }
        ),
        catchError((error) => {
          console.log(error)
          return of(null)
        }),
        mergeMap(() => {
          this.globalSettings = globalSettings
          this.notificationService.displayNotification('Die Änderungen wurden gespeichert.')
          return of(null)
        }),
        mergeMap(() => {
          this.globalSettingsChanged.next(true)
          return of(null)
        })
      )
  }
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsGuard implements CanActivate {


  constructor(
    private globalSettingsService: GlobalSettingsService
  ) { }

  canActivate(): Observable<boolean> {
    if (this.globalSettingsService.globalSettings) {
      return of(true);
    }
    return this.globalSettingsService.loadGlobalSettings()
      .pipe(
        untilDestroyed(this),
        mergeMap(() => of(true)),
      )
  }
}
