import { assertType, get, isNil, nonNil } from '@arzt-direkt/wfa-generic-utils'

import { WfaFormJoi } from '../joi/wfa-form.joi'
import { WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { wfaQuestionsDict } from './../joi/wfa-question/wfa-question.joi'
import { repairWfaForm } from './repair-wfa-form'

/**
 * Validates the input against WFA form schema.
 * @throws {Error} If validation fails or input is nil
 * @param x - Input to validate
 * @returns Validated WFA form
 */
export function assertWfaForm(x: unknown): WfaForm {
  try {
    return assertType(WfaFormJoi, x)
  } catch (err: unknown) {
    // throw detailed info if it is a question type error
    assertIfQuestionTypeError(x, err as Error)
    // throw the default error otherwise
    throw err
  }
}

/**
 * Attempts to repair and validate a WFA form. If initial validation fails,
 * repairs the form structure before revalidating.
 * @param x - Input to validate and potentially repair
 * @returns Validated WFA form
 * @throws {Error} If validation fails after repair attempt
 */
export function repairAndAssertWfaForm(x: unknown): WfaForm {
  try {
    return assertType(WfaFormJoi, x)
  } catch (err: unknown) {
    const asObject = typeof x === 'object' && nonNil(x) ? x : {}
    return assertType(WfaFormJoi, repairWfaForm(asObject))
  }
}

/**
 * Provides detailed validation for individual form questions when top-level validation fails.
 * Extracts the specific question that failed validation and validates it against its type-specific schema.
 * @param x - Original form input
 * @param err - Error from initial validation
 * @throws {Error} With detailed question validation message if applicable
 */
export function assertIfQuestionTypeError(x: unknown, err: Error): void {
  const indices = extractIndices(err.message)
  if (isNil(indices)) return
  const [pageInd, elInd] = indices
  const question = get(x, `pages[${pageInd}].elements[${elInd}]`)
  if (isNil(question)) return
  const questionType = question?.type
  console.log(
    `[assertIfQuestionTypeError]: asserting for question type ${questionType}...`,
  )
  const questionJoi = wfaQuestionsDict[questionType]
  assertType(questionJoi, question)
}

/**
 * Parses page and element indices from WFA form validation error messages.
 * @param message - Error message to parse
 * @returns Tuple of [pageIndex, elementIndex] if found, null otherwise
 * @example
 * extractIndices('Validation failed: "pages[0].elements[30]"') // returns [0, 30]
 * extractIndices('Invalid message') // returns null
 */
export function extractIndices(message: string): [number, number] | null {
  const match = message.match(/pages\[(\d+)\]\.elements\[(\d+)\]/)
  if (!match) return null
  return [parseInt(match[1]), parseInt(match[2])]
}
