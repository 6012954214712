import { DisplayOption, SurveyjsResponse } from '@arzt-direkt/wfa-definitions'
import { isNonEmptyObject } from '@arzt-direkt/wfa-generic-utils'
import { Model } from 'survey-core'

/**
 * Updates a SurveyJS model with response data while preserving default values.
 *
 * In `wfa`, there are two types of values that prefill the form:
 * - Prefill values (`surveyjsResponse`) that are loaded from tomedo or from other
 *   already filled forms. These are not stored inside WfaForm but are loaded
 *   externally and set in wfa.
 * - Default values that are set in the editor. These are stored inside WfaForm
 *   and are set automatically by SurveyJS.
 *
 * The challenge is managing the interaction between these two prefill types.
 * This function sets prefill values (`surveyjsResponse`) in a way that preserves
 * default values by using `model.setValue()` instead of updating `model.data` directly.
 * Prefill values have a higher priority than default values.
 * For e2e-tests, see `vorbefuellung.spec.ts`.
 *
 * @param {Model} model - The SurveyJS Model instance to update
 * @param {SurveyjsResponse | undefined} surveyjsResponse - The response data to apply to the model
 */
export function updateModelWithResponse(
  model: Model,
  surveyjsResponse: SurveyjsResponse | undefined,
  displayOption: DisplayOption,
) {
  suppressDefaultValuesInReadOnlyMode(model, displayOption)
  addPrefillValues(model, surveyjsResponse)
}

function suppressDefaultValuesInReadOnlyMode(
  model: Model,
  displayOption: DisplayOption,
) {
  if (displayOption !== 'editable') {
    model.data = {}
  }
}

function addPrefillValues(
  model: Model,
  surveyjsResponse: SurveyjsResponse | undefined,
) {
  if (!isNonEmptyObject(surveyjsResponse)) {
    return
  }

  Object.keys(surveyjsResponse).forEach(key => {
    const value = surveyjsResponse[key]
    if (value !== undefined) {
      model.setValue(key, value)
    }
  })
}
