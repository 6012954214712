import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { WfaFormWithResponse as Fwr } from '@arzt-direkt/wfa-definitions'
import { FormSubmissionError, set } from '@arzt-direkt/wfa-generic-utils'
import { catchError, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs'

import { isAdisWfaEnv, WfaEnv } from '../../../../../environments/wfa-env'
import { getDisplayableErrorRoute } from '../../../../user-message/displayable-errors'
import { httpOptions } from '../../../../utility/http-params'
import { replaceSpecialCharacters } from '../../../../utility/replace-special-characters'
import { replaceUmlauts } from '../../../../utility/replace-umlauts'
import { LinkType } from '../single-or-multilink.types'
import { encryptFormResponseData } from './encrypt-form-response-data'
import { LinkData } from './parse-raw-data'

const pathDict: Record<LinkType, string> = {
  single: 'updateWfaFormResponse',
  multi: 'updateWfaFormResponse',
  perma: 'createWfaFormResponseFromPermalink',
}

export function submitResponse(
  ld: Pick<LinkData, 'fwrs' | 'type'>,
  httpClient: HttpClient,
  wfaEnv: WfaEnv,
  router: Router,
): Observable<unknown> {
  const path = pathDict[ld.type as LinkType]
  const url = `${wfaEnv.serverUrl}${wfaEnv.serverUrlSubdirectory}/${path}`

  const withSubmissionDate = ld.fwrs.map(fwr => ({
    ...fwr,
    formResponse: {
      ...fwr.formResponse,
      surveyjsResponse: replaceSpecialCharacters(
        fwr.formResponse.surveyjsResponse ?? {},
      ),
      submittedAt: new Date().valueOf(),
    },
  }))

  return forkJoin(encryptFwr(withSubmissionDate, wfaEnv)).pipe(
    switchMap(body => httpClient.post(url, body, httpOptions)),
    tap(response =>
      wfaEnv.logger.log('[WFA] Form submission success:', { url, response }),
    ),
    catchError(error => {
      wfaEnv.logger.error('[WFA] Form submission failed:', { url, error })
      router.navigate([
        `${wfaEnv.frontendUrlSubdirectory}/user-message/`,
        getDisplayableErrorRoute('q5709', error?.message),
      ])
      throw new FormSubmissionError(error?.message ?? 'Form submission failed')
    }),
  )
}

function encryptFwr(fwrs: Fwr[], wfaEnv: WfaEnv) {
  return fwrs.map(fwr => encryptOneFwr(fwr, wfaEnv))
}

function encryptOneFwr(fwr: Fwr, wfaEnv: WfaEnv) {
  return isAdisWfaEnv(wfaEnv)
    ? encryptFormResponseData(
        fwr,
        (fwr.certificate as string) ??
          (fwr.form['certificate'] as string) ??
          (fwr.formResponse['certificate'] as string),
        wfaEnv,
      ).pipe(map(encrypted => ({ ...encrypted, form: fwr.form })))
    : of({
        form: replaceUmlauts(fwr.form), // indes-server.ts has troubles parsing umlauts for some reason
        formResponse: replaceUmlauts(fwr.formResponse),
        formIsValid: fwr.formIsValid,
      })
}
