<div class="logo">
  <picture>
    <source type="image/png" srcset="
          assets/whitelabeling/mein-rezept-online/mein-rezept-online--colored.png 400w,
          assets/whitelabeling/mein-rezept-online/mein-rezept-online--colored@2x.png 800w,"
      sizes=" (max-width: 25rem) 100vw, 25rem">
    <img src="assets/whitelabeling/mein-rezept-online/mein-rezept-online--colored.png" alt="meinRezept.online">
  </picture>
</div>


<div class="text">
  <ng-container *ngIf="mode === 'arzt'">
    <p>
      {{'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.TEXT-DOCTOR' | translate}}
    </p>
  </ng-container>

  <ng-container *ngIf="mode === 'patient'">
    <p>
      {{'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.TEXT-PATIENT' | translate}}
    </p>
  </ng-container>
</div>


<div class="c-button-group" style="justify-content: center; margin-top: 2rem;">
  <a mat-flat-button color="primary" href="https://meinrezept.online/arztpraxen-arzt-direkt/" target="_blank">
    <span style="font-weight: 600;">{{'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.LEARN-MORE' | translate}} </span>
    <mat-icon iconPositionEnd>open_in_new</mat-icon>
  </a>
</div>

<ng-container *ngIf="mode === 'patient'">
  <div class="c-button-group app-store-badges" style="justify-content: center; margin-top: 0;">
    <a
      href='https://play.google.com/store/apps/details?id=com.meinrezeptonlineapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
        [alt]="'WHITELABEL-SECTION-MEIN-REZEPT-ONLINE.IMG-LABEL' | translate" height="59px" loading="lazy"
        src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png' /></a>
    <a href="https://apps.apple.com/de/app/meinrezept-online/id1476692666?mt=8"
      style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-11-12&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:46px;"></a>
  </div>
</ng-container>