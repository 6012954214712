import Joi from 'joi'

import { NilError, ValidationError } from './error/wfs-error'
import { isNil } from './not-lodash/is-nil'

type JoiSchema<T> =
  | Joi.ObjectSchema<T>
  | Joi.AlternativesSchema<T>
  | Joi.StringSchema

export function matchesSchema<T>(joiSchema: JoiSchema<T>, x: unknown): x is T {
  if (isNil(x)) return false
  const { error } = joiSchema.validate(x)
  return isNil(error)
}

export function assertType<T>(joiSchema: Joi.AnySchema<T>, x: unknown): T {
  if (isNil(x)) throw new NilError('[assertType]: Validation failed: nil input')

  const { error, value } = joiSchema.validate(x, { convert: true }) as {
    error?: Joi.ValidationError
    value: T
  }
  if (error) {
    throw new ValidationError(
      `[assertType]: Validation failed: ${error.message}`,
      {
        details: error.details,
      },
    )
  }

  return value
}

export function validateArray<T>(joiSchema: JoiSchema<T>, x: unknown): T[] {
  const arraySchema = Joi.array().items(joiSchema)
  return assertType(arraySchema, x)
}
