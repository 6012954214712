import { isNil, Nullable } from '@arzt-direkt/wfa-generic-utils'
import Joi from 'joi'

import { SurveyjsResponse } from '../interfaces-autogen-from-joi'
import { SurveyjsFileResponseFormatJoi } from './file-upload/surveyjs-file-response-format.joi'
import { surveyjsResponseJoiExample } from './surveyjs-response.joi.example'
import { wfaKeys } from './wfa-key.joi'

// Predefined set of allowed keys
// it was used in .pattern of SurveyjsResponseJoi
// but it was a bit too strict, therefore
// it is unused since 2025-01-30
// and can be safely removed after
// 2025-06-30
const _allowedKeys = wfaKeys

// allowed values
const allowedValuesJoi = Joi.alternatives(
  Joi.string(),
  Joi.array().items(Joi.string()),
  Joi.number(),
  Joi.boolean(),
  Joi.array().items(SurveyjsFileResponseFormatJoi),
)

// Define Joi schema
export const SurveyjsResponseJoi = Joi.object()
  .pattern(Joi.string(), allowedValuesJoi)
  .meta({
    className: 'SurveyjsResponse',
  })
  .example(surveyjsResponseJoiExample)

export function hasUnencryptedResponse(
  x: Nullable<SurveyjsResponse>,
): x is Nullable<SurveyjsResponse> {
  if (typeof x === 'string' || isNil(x) || typeof x !== 'object') {
    return false
  }

  return Object.entries(x).some(([_key, value]) => {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    )
  })
}
