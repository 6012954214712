import { getQuestions, MostRelaxedWfaForm } from '@arzt-direkt/wfa-definitions'
import { nonNil } from '@arzt-direkt/wfa-generic-utils'

/**
 * mutates wfaform
 *
 * removes all `defaultValueExpression`-properties before view
 */
export function applyAdi3325Hack(
  form: MostRelaxedWfaForm,
  displayOption?: string,
): void {
  if (displayOption === 'editable') {
    return
  }

  const questions = getQuestions(form)
  questions.forEach(q => {
    if (nonNil(q['defaultValueExpression'])) {
      delete q['defaultValueExpression']
    }
  })
}
