import {
  CoreLocale,
  SurveyjsFileInputFormat,
} from '@arzt-direkt/wfa-definitions'
import {
  FileValidationError,
  isNil,
  FileReadError,
  FileUploadError,
  WfsError,
} from '@arzt-direkt/wfa-generic-utils'
import {
  filter,
  forkJoin,
  fromEvent,
  map,
  mergeMap,
  Observable,
  of,
  take,
  catchError,
} from 'rxjs'
import { SurveyModel, UploadFilesEvent } from 'survey-core'

import { FileUploadSettings, settings } from '../settings'
import { formatFile } from './format-file'
import { getLocalizedErrorMessage } from './get-localized-error-message'
import { isFileImage } from './image-upload/is-file-image'
import { scaleImage } from './image-upload/scale-image'

type Base64String = string

export function handleFileUpload(
  surveyModel: SurveyModel,
  options: UploadFilesEvent,
): void {
  uploadFiles(options.files, settings, surveyModel.locale as CoreLocale)
    .pipe(
      take(1),
      catchError(error => {
        const message =
          error instanceof WfsError
            ? error.message
            : 'Unexpected file upload error'
        options.callback('error', message)
        return []
      }),
    )
    .subscribe(results => handleUploadResults(results, options))
}

function uploadFiles(
  files: File[],
  settings: FileUploadSettings,
  locale: CoreLocale,
): Observable<SurveyjsFileInputFormat[]> {
  const observables = files.map(file => uploadFile(file, settings, locale))
  return forkJoin(observables)
}

function uploadFile(
  file: File,
  settings: FileUploadSettings,
  locale: CoreLocale,
): Observable<SurveyjsFileInputFormat> {
  assertFile(file, settings, locale)
  const { name, type } = file
  const fileIsImage = isFileImage(type)

  const reader = new FileReader()
  reader.readAsDataURL(file)

  return fromEvent(reader, 'load').pipe(
    take(1),
    mergeMap(() => {
      if (reader.error) {
        throw new FileUploadError(
          getLocalizedErrorMessage(locale, 'uploadError', {
            errorMessage: reader.error.message,
          }),
          { originalError: reader.error },
        )
      }

      const base64String = reader.result as Base64String
      if (isNil(base64String)) {
        throw new FileReadError(
          getLocalizedErrorMessage(locale, 'readFileError', { name }),
        )
      }

      return fileIsImage ? scaleImage(base64String, settings) : of(base64String)
    }),
    map((base64String: Base64String) =>
      formatFile(
        name,
        fileIsImage ? settings.image.saveType : type,
        base64String,
      ),
    ),
    catchError(error => {
      if (error instanceof WfsError) {
        throw error
      }
      throw new FileUploadError('Unexpected file upload error', {
        originalError: error,
      })
    }),
  )
}

function assertFile(
  file: File,
  settings: FileUploadSettings,
  locale: CoreLocale,
): File {
  const { mediaTypes, maxMegaByteSize } = settings
  const { type, name, size } = file

  if (!mediaTypes.includes(type)) {
    throw new FileValidationError(
      getLocalizedErrorMessage(locale, 'fileTypeError', { name, type }),
      { allowedTypes: mediaTypes },
    )
  }

  const maxByteSize = maxMegaByteSize * Math.pow(1024, 2)
  if (size > maxByteSize) {
    throw new FileValidationError(
      getLocalizedErrorMessage(locale, 'fileSizeError', {
        name,
        maxMegaByteSize,
      }),
      { maxSize: maxByteSize, actualSize: size },
    )
  }

  return file
}

function handleUploadResults(
  results: SurveyjsFileInputFormat[],
  options: UploadFilesEvent,
): void {
  options.callback('success', results)
}
