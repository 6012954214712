import Joi from 'joi'

import { LocStringJoi } from '../loc-string.joi'
import { MinBaseQuestionJoi } from './base-question.joi'
import { example } from './examples/wfa-html-question.joi.example'

export const WfaHtmlQuestionJoi = MinBaseQuestionJoi.keys({
  description: LocStringJoi.optional(),
  type: Joi.string().valid('html').required(),
  html: LocStringJoi.optional(),
})
  .meta({
    className: 'WfaHtmlQuestion',
  })
  .example(example)
