import { RecordKey } from './../types/record-key'

/** Common HTTP status codes for REST APIs */
export enum HttpStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 434,
  INTERNAL_SERVER_ERROR = 500,
}

/** Standard error codes for API responses */
export enum ErrorCode {
  VAL_INVALID_INPUT = 'VAL_001',
  VAL_MISSING_FIELD = 'VAL_002',
  RES_NOT_FOUND = 'RES_001',
  SYS_INTERNAL_ERROR = 'SYS_001',
}

/** Standardized error response structure for API endpoints */
export interface ErrorResponse {
  error: {
    name: string
    message: string
    statusCode: HttpStatus
    errorCode: ErrorCode
    details?: Record<RecordKey, unknown>
  }
}

/**
 * Base error class for WFS application
 * @extends Error
 */
export class WfsError extends Error {
  constructor(
    message: string,
    public readonly statusCode: HttpStatus = HttpStatus.INTERNAL_SERVER_ERROR,
    public readonly errorCode: ErrorCode = ErrorCode.SYS_INTERNAL_ERROR,
    public readonly details?: Record<RecordKey, unknown>,
  ) {
    super(message)
    this.name = 'WfsError'
  }

  static fromUnknown(
    err: unknown,
    details: Record<RecordKey, unknown> = {},
  ): WfsError {
    if (err instanceof WfsError) return err

    const message = err instanceof Error ? err.message : 'Unknown error'
    return new WfsError(
      message,
      HttpStatus.INTERNAL_SERVER_ERROR,
      ErrorCode.SYS_INTERNAL_ERROR,
      {
        original: err,
        ...details,
      },
    )
  }

  toJSON(): ErrorResponse {
    return {
      error: {
        name: this.name,
        message: this.message,
        statusCode: this.statusCode,
        errorCode: this.errorCode,
        ...(this.details && { details: this.details }),
      },
    }
  }
}

/**
 * Error class for validation failures
 * @extends WfsError
 */
export class ValidationError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, HttpStatus.BAD_REQUEST, ErrorCode.VAL_INVALID_INPUT, details)
    this.name = 'ValidationError'
  }
}

/**
 * Error class for null/undefined values
 * @extends WfsError
 */
export class NilError extends WfsError {
  constructor(message = 'Unexpected null or undefined value') {
    super(
      message,
      HttpStatus.INTERNAL_SERVER_ERROR,
      ErrorCode.SYS_INTERNAL_ERROR,
    )
    this.name = 'NilError'
  }
}

/**
 * Error class for resource not found scenarios
 * @extends WfsError
 */
export class ResourceNotFoundError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, HttpStatus.NOT_FOUND, ErrorCode.RES_NOT_FOUND, details)
    this.name = 'ResourceNotFoundError'
  }
}

/**
 * Error class for file validation failures
 * @extends ValidationError
 */
export class FileValidationError extends ValidationError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, details)
    this.name = 'FileValidationError'
  }
}

export class FileReadError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, HttpStatus.BAD_REQUEST, ErrorCode.VAL_INVALID_INPUT, details)
    this.name = 'FileReadError'
  }
}

export class FileUploadError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, HttpStatus.BAD_REQUEST, ErrorCode.VAL_INVALID_INPUT, details)
    this.name = 'FileUploadError'
  }
}

export class FormPublishError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(
      message,
      HttpStatus.INTERNAL_SERVER_ERROR,
      ErrorCode.SYS_INTERNAL_ERROR,
      details,
    )
    this.name = 'FormPublishError'
  }
}

export class FallbackError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(
      message,
      HttpStatus.INTERNAL_SERVER_ERROR,
      ErrorCode.SYS_INTERNAL_ERROR,
      {
        type: 'unexpected_error',
        ...details,
      },
    )
    this.name = 'FallbackError'
  }
}

export class FormSubmissionError extends WfsError {
  constructor(message: string, details?: Record<RecordKey, unknown>) {
    super(message, HttpStatus.BAD_REQUEST, ErrorCode.VAL_INVALID_INPUT, details)
    this.name = 'FormSubmissionError'
  }
}
