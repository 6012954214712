import Joi from 'joi'

export const IdJoi = Joi.alternatives()
  .try(
    Joi.string().length(24).hex(),
    Joi.object().keys({
      $oid: Joi.string().length(24).hex().required(),
    }),
  )
  .required()
  .description(
    `This is a manual attempt to emulate mongoose.Types.ObjectId, which is the same
as bson's ObjectId specified in arzt-direkt/node_modules/bson/src/objectid.ts.

Since the bson definition of ObjectId is rather involved, this schema is rather
limited.  As far as tsc is concerned, the resulting Id ts type seems to be
completely compatible with mongoose.Types.ObjectId.`,
  )
  .meta({ className: 'Id', _mongoose: { type: 'ObjectId' } })
