import { nonNil } from '../not-lodash/is-nil'

/**
 * Joins arguments with a specified separator, stringifying non-string values.
 * @param separator - Character(s) to join with
 * @param args - Values to join
 * @returns Separator-joined string of arguments, with non-strings JSON stringified
 */
export const joinStrings = (separator: string, ...args: unknown[]): string =>
  args
    .filter(arg => nonNil(arg))
    .map(arg => (typeof arg === 'string' ? arg : JSON.stringify(arg, null, 2)))
    .filter(arg => arg !== '')
    .join(separator)

/**
 * Joins arguments with spaces, stringifying non-string values.
 * @param args - Values to join
 * @returns Space-separated string of arguments, with non-strings JSON stringified
 */
export const joinWithSpace = (...args: unknown[]): string =>
  joinStrings(' ', ...args)
