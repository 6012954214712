import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { notNullish } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, distinctUntilChanged, map, tap, Subject } from 'rxjs'

export type MatInputPopupUserInput = 'save' | 'cancel' | 'abort'
export interface MatInputPopupI18n {
  editInput: string
  label: string
  save: string
  cancel: string
}
export interface MatInputPopupParams {
  show$: BehaviorSubject<boolean>
  userInput$: Subject<MatInputPopupUserInput>
  matInputControl: FormControl
  matInputPopupI18n: MatInputPopupI18n
}

@UntilDestroy()
@Component({
  selector: 'wfa-mat-input-popup',
  standalone: true,
  template: `
    @if (show) {
      <div class="overlay" (click)="onAbort()">
        <div class="dialog" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill" style="width:50vw;">
            <mat-label data-selenium-id="mat-label-@mat-input-popup/label">
              {{ params.matInputPopupI18n.label | translate }}
            </mat-label>
            <input
              matInput
              [formControl]="params.matInputControl"
              autofocus
              data-selenium-id="input-@mat-input-popup/input"
            />
            <ng-container *ngIf="notNullish(params.matInputControl.errors)">
              <mat-error
                *ngFor="
                  let error of getErrorMessages(params.matInputControl.errors)
                "
              >
                {{ error | translate }}
              </mat-error>
            </ng-container>
          </mat-form-field>

          <div class="button-group">
            <button
              mat-button
              color="primary"
              (click)="onSave()"
              [disabled]="notNullish(errors) || controlValue === ''"
              data-selenium-id="button-@mat-input-popup/save"
            >
              {{ params.matInputPopupI18n.save | translate }}
            </button>
            <button
              mat-button
              color="warn"
              (click)="onCancel()"
              data-selenium-id="button-@mat-input-popup/cancel"
            >
              {{ params.matInputPopupI18n.cancel | translate }}
            </button>
          </div>
        </div>
      </div>
    }
  `,
  styleUrls: [`./overlay-dialog.scss`],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class MatInputPopupComponent {
  @Input() params: MatInputPopupParams = {
    show$: new BehaviorSubject<boolean>(false),
    userInput$: new Subject<MatInputPopupUserInput>(),
    matInputControl: new FormControl(''),
    matInputPopupI18n: {
      editInput: 'WFA.MAT-INPUT-POPUP.DEFAULT.EDIT-INPUT',
      label: 'WFA.MAT-INPUT-POPUP.DEFAULT.LABEL',
      save: 'WFA.MAT-INPUT-POPUP.DEFAULT.SAVE',
      cancel: 'WFA.MAT-INPUT-POPUP.DEFAULT.CANCEL',
    },
  }

  readonly notNullish = notNullish

  show = false
  controlValue: string = ''
  errors: ValidationErrors | null = null

  onSave() {
    this.params.userInput$.next('save')
    this.params.show$.next(false)
  }

  onCancel() {
    this.params.matInputControl.markAsUntouched()
    this.params.matInputControl.markAsPristine()
    this.params.userInput$.next('cancel')
    this.params.show$.next(false)
  }

  onAbort() {
    this.params.matInputControl.markAsUntouched()
    this.params.matInputControl.markAsPristine()
    this.params.userInput$.next('abort')
    this.params.show$.next(false)
  }

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.params.show$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((show) => {
        this.show = show
      })

    this.params.matInputControl.valueChanges
      .pipe(
        tap((newControlValue) => {
          this.controlValue = newControlValue
        }),
        map(() => this.params.matInputControl.errors),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.errors = this.params.matInputControl.errors
      })
  }

  // helpers
  getErrorMessages(errors: ValidationErrors | null): string[] {
    if (!errors) return []
    return Object.keys(errors).map((key) => {
      // You might want to customize this based on your error structure
      return `WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.${key.toUpperCase()}`
    })
  }
}
