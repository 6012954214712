import Joi from 'joi'

export const SurveyjsFileResponseFormatJoi = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().required(),
  content: Joi.string().required(),
})
  .description(
    `Represents the format of files in the SurveyJS response.
 * Files are returned in this structure, including their name, type, and content (base64 string).`,
  )
  .meta({ className: 'SurveyjsFileResponseFormat' })
