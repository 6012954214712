import {
  repairAndAssertWfaForm,
  FormResponseOrigin,
  GenericRouteResponse200 as Response,
  WfaForm,
  WfaFormResponseJoi,
  WfaFormWithResponse as Fwr,
  WfaFormWithResponseJoi as FwrJoi,
} from '@arzt-direkt/wfa-definitions'
import {
  validateArray,
  assertType,
  nonNil,
  isNil,
} from '@arzt-direkt/wfa-generic-utils'

import { PermalinkMissingError } from './../../permalink-missing-error'
import { LoadableLinkType } from './../single-or-multilink.state'

export interface LinkData {
  type: LoadableLinkType
  fwrs: Fwr[]
  alreadySubmitted: boolean
}

function validateFormInsideFWR(fwr: Fwr) {
  return {
    ...fwr,
    form: repairAndAssertWfaForm(fwr.form),
  }
}

function alreadySubmitted(fwr: Fwr): boolean {
  const submittedAt = fwr.formResponse?.submittedAt
  return nonNil(submittedAt) && submittedAt > 0
}

function parseMultilink(data: Fwr[]): LinkData {
  const raw = validateArray(FwrJoi, data)
  const fwrs = raw.map(validateFormInsideFWR)
  return {
    fwrs,
    type: 'multi',
    alreadySubmitted: fwrs.some(alreadySubmitted),
  }
}

function parsePermalink(data: WfaForm): LinkData {
  const form = repairAndAssertWfaForm(data)
  if (isNil(form.permalink)) {
    throw new PermalinkMissingError()
  }

  const placeholderId = '000000000000000000000000'
  const formResponse = {
    _id: placeholderId,
    responseId: placeholderId,
    wfaFormId: form.wfaFormId,
    wfaFormVersionId: form.wfaFormVersionId,
    praxisId: form.praxisId,
    surveyjsResponse: {},
    origin: 'missing' as FormResponseOrigin,
    submittedAt: -1,
  }
  const fwrs = [
    {
      form,
      formResponse,
      certificate: form.certificate,
    },
  ]

  return { fwrs, type: 'perma', alreadySubmitted: false }
}

export function parseSingleLink(data: Fwr): LinkData {
  const form = repairAndAssertWfaForm((data as Fwr).form)
  const formResponse = assertType(
    WfaFormResponseJoi,
    (data as Fwr).formResponse,
  )
  const fwrs = [
    {
      ...(data as object),
      form,
      formResponse,
    },
  ]
  return { fwrs, type: 'single', alreadySubmitted: alreadySubmitted(fwrs[0]) }
}

export function parseRawData(withMetadata: unknown): LinkData {
  const data = (withMetadata as Response).queryResult

  // probably multilink
  if (Array.isArray(data)) {
    return parseMultilink(data as Fwr[])
  }

  // probably permalink
  if (nonNil((data as WfaForm)?.wfaFormId)) {
    return parsePermalink(data as WfaForm)
  }

  // probably single link
  if (nonNil((data as Fwr)?.form)) {
    return parseSingleLink(data as Fwr)
  }

  throw new Error(
    `[parseRawData]: validation failed for response ${JSON.stringify(withMetadata)}`,
  )
}
