import { TestLimitDialogComponent } from './../test/test-ng-components/test-limit-dialig.component'
import { Route } from '@angular/router'

import { TestZsSupportComponent } from '../test/test-zs-support.component'
import { TestDoctorFormTableManagementComponent } from './../test/test-doctor-form-table-management.component'
import { TestMatInputPopupComponent } from './../test/utility-components/test-mat-input-popup.component'
import { adisRoutes } from './adis.routes'
import { lotosRoutes } from './lotos.routes'
import { fallbackRoute } from './shared.routes'
import {
  aboutRoute,
  adisEditorRoute,
  supportEditorRoute,
  testEditorRoute,
  testNotificationRoute,
  testViewerRoute,
  testWebviewViewerRoute,
} from './shared.routes'

const adisWithoutFallback = adisRoutes.filter((r) => r.path !== '**')
const lotosWithoutFallback = lotosRoutes.filter((r) => r.path !== '**')

export const testZsSupportRoute: Route = {
  path: 'test-zs-support',
  component: TestZsSupportComponent,
}

export const testMatInputPopupRoute: Route = {
  path: 'test-mat-input-popup',
  component: TestMatInputPopupComponent,
}

export const testLimitDialogComponentRoute: Route = {
  path: 'test-limit-dialog',
  component: TestLimitDialogComponent,
}

export const testDoctorFormTableManagementRoute: Route = {
  path: 'test-doctor-form-table-management',
  component: TestDoctorFormTableManagementComponent,
}

export const indesRoutes: Route[] = [
  ...lotosWithoutFallback,
  ...adisWithoutFallback,
  aboutRoute,
  {
    ...adisEditorRoute,
    path: 'wfa/adis-editor',
  },
  supportEditorRoute,
  testDoctorFormTableManagementRoute,
  testEditorRoute,
  testLimitDialogComponentRoute,
  testMatInputPopupRoute,
  testNotificationRoute,
  testViewerRoute,
  testWebviewViewerRoute,
  testZsSupportRoute,
  // fallback intercepts all other cases and is last
  fallbackRoute,
]
