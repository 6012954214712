import { InputType } from '@arzt-direkt/wfa-definitions'
import { Question } from 'survey-core'

export function setDefaultValueExpressionForDate(
  newInputType: InputType,
  question: Question,
) {
  if (newInputType === 'date') {
    question.defaultValueExpression = 'today()'
  }
}
