import { ValidationError } from './../error/wfs-error'

export function asString(value: unknown): string {
  if (typeof value === 'string') return value

  try {
    if (value && typeof value.toString === 'function') {
      const result = value.toString()
      if (typeof result === 'string') return result
    }
  } catch (e) {
    throw new ValidationError(
      `Failed to convert value ${JSON.stringify(value)} to string.`,
    )
  }

  throw new ValidationError(
    `Value ${JSON.stringify(value)} cannot be converted to string`,
  )
}
