import {
  getQuestions,
  ResponseType,
  SurveyjsResponse,
  WfaForm,
  WfaQuestion,
  WfaQuestionWithInputType,
} from '@arzt-direkt/wfa-definitions'
import { nonNil } from '@arzt-direkt/wfa-generic-utils'

import {
  QUESTION_TYPES_MULTI_SELECT,
  QUESTION_TYPES_SINGLE_SELECT,
} from '../../types'

const formResponseDictionary: Record<string, string | number | boolean> = {
  boolean: true,
  text: 'Das ist eine Freitext-Antwort',
  number: 1,
  date: '2000-01-01',
  birthdate: '2000-01-02',
  gender: 'D',
  $wfaPatientEmail$: 'a@b.de',
  $wfaPatientKrankenkasse$: 'Techniker Krankenkasse',
  $wfaPatientLand$: 'DEU',
  $wfaPatientNachname$: 'Muster Patient Nachname',
  $wfaPatientNamenszusatz$: 'Muster Patient Namenszusatz',
  $wfaPatientOrt$: 'Berlin',
  $wfaPatientPlz$: '12345',
  $wfaPatientStrasseHausnummer$: '66',
  $wfaPatientTelefonMobil$: '0 1234 567 8910',
  $wfaPatientTelefonPrivat$: '1 2345 678 9101',
  $wfaPatientTitel$: 'Priv. Doz.',
  $wfaPatientVersicherungsnummer$: '123456780',
  $wfaPatientVorname$: 'Muster Patient Vorname',
}

function lookUpFormResponseValue(
  type: string,
  name: string,
): string | number | boolean | undefined {
  return nonNil(formResponseDictionary[name])
    ? formResponseDictionary[name]
    : formResponseDictionary[type]
}

function extractFirstResponseOption(
  wfaQuestion: WfaQuestion,
): string | undefined {
  const responseOptions = (wfaQuestion as unknown as any)['choices']
  if (responseOptions === undefined) return undefined
  const firstResponseOption = responseOptions[0]
  if (firstResponseOption === undefined) return undefined
  return firstResponseOption['value']
}

function packResponseOption(wfaQuestion: WfaQuestion): string[] | undefined {
  const responseOption = extractFirstResponseOption(wfaQuestion)
  if (responseOption === undefined) return undefined
  return [responseOption]
}

function createQuestionResponse(
  wfaQuestion: WfaQuestionWithInputType,
): ResponseType | undefined {
  const questionType = wfaQuestion.type
  if (QUESTION_TYPES_SINGLE_SELECT.includes(questionType))
    return extractFirstResponseOption(wfaQuestion)
  if (QUESTION_TYPES_MULTI_SELECT.includes(questionType))
    return packResponseOption(wfaQuestion)
  return lookUpFormResponseValue(
    wfaQuestion['inputType'] ?? wfaQuestion['wfaInputType'] ?? questionType,
    wfaQuestion['name'],
  )
}

export function generateFormResponse(
  wfaForm: Pick<WfaForm, 'wfaFormId' | 'title' | 'wfaVersion' | 'pages'>,
): SurveyjsResponse {
  const surveyjsResponse: SurveyjsResponse = {}
  const wfaQuestions = getQuestions(wfaForm)
  wfaQuestions.forEach(wfaQuestion => {
    const questionIdentifier = wfaQuestion.name
    const response = createQuestionResponse(wfaQuestion)
    if (nonNil(response)) {
      surveyjsResponse[questionIdentifier] = response
    }
  })
  return surveyjsResponse
}
