<div class="logo">
  <picture>
    <img src="assets/whitelabeling/reha-aktiv/reha-aktiv--colored@2x.png" loading="lazy" alt="REHAaktiv">
  </picture>
</div>


<div class="text">
  <ng-container *ngIf="mode === 'arzt'">
    <p>

    </p>
  </ng-container>

  <ng-container *ngIf="mode === 'patient'">
    <div [innerHTML]="'WHITELABEL-SECTION-REHA-AKTIV.TEXT' | translate | sanitizeHtml"></div>
  </ng-container>
</div>


<div class="c-button-group" style="justify-content: center; margin-top: 2rem;">
  <a mat-flat-button color="primary" href="https://www.reha-aktiv2000.de/" target="_blank">
    <span style="font-weight: 600;">{{'WHITELABEL-SECTION-REHA-AKTIV.LEARN-MORE' | translate}} </span>
    <mat-icon iconPositionEnd>open_in_new</mat-icon>
  </a>
</div>