import { getHslaString } from '../../../theming/color-utils'
import { applyToValues } from '../../../utils/apply-to-values'
import {
  AdColors,
  HslaAdColors,
  HslaAttributes,
  HslaColor,
  NonHslaAdColors,
} from '../../interfaces'

// tomedo® Red (RGB: 219, 31, 62)
const primaryColor = { h: 349, s: 75, l: 49, a: 1 }

export const nonHslaTomedoColors: NonHslaAdColors = {
  modeColor: 'white',
  mainValue: primaryColor.h.toString(),
  mainSaturation: primaryColor.s.toString(),
  mainLigtness: primaryColor.l.toString(),
  primaryColorValues: `${primaryColor.h}, ${primaryColor.s}%, ${primaryColor.l}%`,
}

export const hslaTomedoColors: HslaAdColors = {
  primaryColor,
  primaryBackColor: { h: 0, s: 0, l: 0, a: 0 },
  // tomedo® Green (RGB: 56, 175, 79)
  secondaryBackColor: { h: 130, s: 52, l: 45, a: 1 },
  // Lighter Red (RGB: 255, 89, 103)
  primaryCardColor: { h: 355, s: 100, l: 67, a: 1 },
  // Blue (RGB: 45, 152, 218)
  secondaryCardColor: { h: 204, s: 66, l: 52, a: 1 },
  // tomedo® Black (RGB: 56, 56, 56)
  primaryTextColor: { h: 0, s: 0, l: 22, a: 1 },
  // tomedo® Dark Red (RGB: 173, 21, 61)
  secondaryTextColor: { h: 344, s: 78, l: 38, a: 1 },
  primaryTextOnColor: { h: 0, s: 0, l: 100, a: 1 },
  // Light Red (RGB: 242, 65, 89)
  secondaryTextOnColor: { h: 351, s: 89, l: 60, a: 1 },
  // Orange (RGB: 250, 130, 49)
  primaryLineColor: { h: 24, s: 95, l: 59, a: 1 },
  // Yellow (RGB: 254, 211, 48)
  secondaryLineColor: { h: 47, s: 99, l: 59, a: 1 },
  shadowColor: { h: 349, s: 75, l: 49, a: 0.16 },
  buttonColor: primaryColor,
  buttonTextColor: { h: 0, s: 0, l: 100, a: 1 },
  // Purple (RGB: 136, 84, 208)
  buttonTextOnColor: { h: 262, s: 57, l: 57, a: 1 },
  // Darker Red (RGB: 138, 0, 41)
  errorColor: { h: 339, s: 100, l: 27, a: 1 },
  // tomedo® Green
  successColor: { h: 130, s: 52, l: 45, a: 1 },
  // Orange
  warningColor: { h: 24, s: 95, l: 59, a: 1 },
  // Blue
  infoColor: { h: 204, s: 66, l: 52, a: 1 },
  backgroundColorTertiary: { h: 47, s: 10, l: 98, a: 1 },
} as const

export const defaultTomedoColors = Object.assign(
  {},
  nonHslaTomedoColors,
  applyToValues<HslaAttributes, HslaColor, string>(
    getHslaString,
    hslaTomedoColors,
  ),
) as AdColors
