import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Route, Params } from '@angular/router'
import { staticIdDict } from '@arzt-direkt/wfa-definitions'
import { ZsSupportComponent } from '../adis/zs-support/zs-support.component'

/**
 * Sets a property on an object from params if the param exists
 * @template T Type of the target object
 * @template K Keys of the target object
 * @param target - Object to update
 * @param params - Source params object
 * @param key - Key to check and update
 */
function updateFromParams<T extends object, K extends keyof T>(
  target: T,
  params: Params,
  key: K & string,
): void {
  if (params[key]) {
    target[key] = params[key]
  }
}

@Component({
  standalone: true,
  selector: 'app-test-zs-support',
  template: `
    <wfa-zs-support
      [praxisId]="praxisId"
      [vertriebspartnerKennung]="vertriebspartnerKennung"
    ></wfa-zs-support>
  `,
  imports: [ZsSupportComponent],
})
export class TestZsSupportComponent implements OnInit {
  praxisId: string = staticIdDict.zollsoft
  vertriebspartnerKennung?: string

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      updateFromParams(this, params, 'praxisId')
      updateFromParams(this, params, 'vertriebspartnerKennung')
    })
  }
}

/**
 * Route configuration for the TestZsSupportComponent.
 * Supports optional matrix parameters for praxisId and vertriebspartnerKennung.
 *
 * @example
 * Valid route patterns:
 * - /test-zs-support
 * - /test-zs-support;praxisId=123
 * - /test-zs-support;vertriebspartnerKennung=456
 * - /test-zs-support;praxisId=123;vertriebspartnerKennung=456
 */
export const testZsSupportRoute: Route = {
  path: 'test-zs-support',
  component: TestZsSupportComponent,
  children: [],
}
