import Joi from 'joi'

export const LocStringJoi = Joi.object()
  .keys({
    ar: Joi.string().allow('').optional(),
    bg: Joi.string().allow('').optional(),
    ca: Joi.string().allow('').optional(),
    cs: Joi.string().allow('').optional(),
    cy: Joi.string().allow('').optional(),
    da: Joi.string().allow('').optional(),
    de: Joi.string().allow('').required(),
    default: Joi.string().allow('').optional(),
    en: Joi.string().allow('').optional(),
    es: Joi.string().allow('').optional(),
    et: Joi.string().allow('').optional(),
    eu: Joi.string().allow('').optional(),
    fa: Joi.string().allow('').optional(),
    fi: Joi.string().allow('').optional(),
    fr: Joi.string().allow('').optional(),
    gr: Joi.string().allow('').optional(),
    he: Joi.string().allow('').optional(),
    hi: Joi.string().allow('').optional(),
    hr: Joi.string().allow('').optional(),
    hu: Joi.string().allow('').optional(),
    id: Joi.string().allow('').optional(),
    is: Joi.string().allow('').optional(),
    it: Joi.string().allow('').optional(),
    ja: Joi.string().allow('').optional(),
    ka: Joi.string().allow('').optional(),
    kk: Joi.string().allow('').optional(),
    ko: Joi.string().allow('').optional(),
    lt: Joi.string().allow('').optional(),
    lv: Joi.string().allow('').optional(),
    mk: Joi.string().allow('').optional(),
    ms: Joi.string().allow('').optional(),
    nl: Joi.string().allow('').optional(),
    'nl-BE': Joi.string().allow('').optional(),
    no: Joi.string().allow('').optional(),
    pl: Joi.string().allow('').optional(),
    pt: Joi.string().allow('').optional(),
    'pt-br': Joi.string().allow('').optional(),
    ro: Joi.string().allow('').optional(),
    rs: Joi.string().allow('').optional(),
    ru: Joi.string().allow('').optional(),
    sk: Joi.string().allow('').optional(),
    sv: Joi.string().allow('').optional(),
    sw: Joi.string().allow('').optional(),
    tel: Joi.string().allow('').optional(),
    tg: Joi.string().allow('').optional(),
    th: Joi.string().allow('').optional(),
    tr: Joi.string().allow('').optional(),
    ua: Joi.string().allow('').optional(),
    vi: Joi.string().allow('').optional(),
    'zh-cn': Joi.string().allow('').optional(),
    'zh-tw': Joi.string().allow('').optional(),
  })
  .unknown(true)
  .meta({ className: 'LocString' })
