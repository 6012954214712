<!-- errors -->
<div class="error"
  *ngIf="(doIkParamExist && !isIkParamValid) || ((doItParamExistSingle || doItParamExistMultiple) && !isItParamValid) || isServerError">
  <div
    *ngIf="(doIkParamExist && !isIkParamValid) || ((doItParamExistSingle || doItParamExistMultiple) && !isItParamValid)"
    class="title">
    {{'OTK.BOOKING-PRELIMINARY.ERRORS.INVALID-PARAMETERS' | translate}}
  </div>
  <div *ngIf="isServerError" class="title">
    {{'OTK.BOOKING-PRELIMINARY.ERRORS.SERVER' | translate}}
  </div>
  <span></span><img [src]="failureAssetUrl" loading="lazy">
</div>

<form attr.data-selenium-id="form-ik" [formGroup]="bookingPreliminaryFormGroup"
  *ngIf="!(doIkParamExist && !isIkParamValid) && !((doItParamExistSingle || doItParamExistMultiple) && !isItParamValid) && !isServerError">

  <!-- Bestandspatienten -->
  <ng-container *ngIf="isIkActive && !doIkParamExist">
    <h4>{{ (isZwCodeCorrect ? 'OTK.BOOKING-PRELIMINARY.REGULAR-PATIENT-QUESTION-ZW':
      'OTK.BOOKING-PRELIMINARY.REGULAR-PATIENT-QUESTION') | translate }}</h4>
    <mat-button-toggle-group [hideSingleSelectionIndicator]="true" attr.data-selenium-id="mat-button-toggle-group-ik"
      formControlName="isKnown">
      <!-- yes -->
      <mat-button-toggle attr.data-selenium-id="mat-button-toggle-ik-yes" class="mat-button-toggle--ik" [value]="true">
        <div>
          {{'GLOBAL.YES' | translate}}
        </div>
      </mat-button-toggle>
      <!-- no -->
      <mat-button-toggle attr.data-selenium-id="mat-button-toggle-ik-no" class="mat-button-toggle--ik" [value]="false">
        <div>
          {{'GLOBAL.NO' | translate}}
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>

  <!-- insurance type -->
  <ng-container *ngIf="isItActive && insuranceTypes?.length!==0 && !doItParamExistSingle">
    <h4>{{ (isZwCodeCorrect ? 'OTK.BOOKING-PRELIMINARY.INSURANCE-QUESTION-ZW' :
      'OTK.BOOKING-PRELIMINARY.INSURANCE-QUESTION') |
      translate }}</h4>
    <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="insurance-buttons-container"
      formControlName="insuranceType">
      <!-- insurance types -->
      <ng-container *ngFor="let insuranceTypeAllowed of insuranceTypes">
        <mat-button-toggle attr.data-selenium-id="mat-button-toggle-it-{{insuranceTypeAllowed}}"
          class="mat-button-toggle--it" [value]="insuranceTypeAllowed"
          [ngClass]="bookingPreliminaryFormGroup.controls['insuranceType'].value===insuranceTypeAllowed ? 'mat-button-toggle--active':''">
          <div class="icon-container">
            <mat-icon class="insurance-icon" [svgIcon]="'insurance/' + insuranceTypeAllowed"></mat-icon>
          </div>
          <div class="insurance-text">{{ ('INSTANCE-FORM-INSURANCE.INSURANCE-TYPE.SHORT-NAME-FORMATTED.' +
            insuranceTypeAllowed) | translate }}
          </div>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </ng-container>

  <!-- birhday -->
  <div class="birthday-container" *ngIf="isBirthdateActive">
    <h4>{{ 'OTK.BOOKING-PRELIMINARY.AGE-QUESTION' |
      translate }}</h4>
    <mat-form-field attr.data-selenium-id="mat-form-field-birth-date" class="c-form__row__item--small"
      appearance="outline">
      <mat-label>
        {{ 'PERSONAL-FORM.BIRTHDAY' | translate }}
      </mat-label>
      <input attr.data-selenium-id="input-birthdate" readonly matInput [matDatepicker]="picker"
        [matDatepickerFilter]="MIN_AGE_FILTER" [placeholder]="'GLOBAL.DATE-PLACEHOLDER' | translate"
        formControlName="birthDate" name="bday" autocomplete="bday" #birthDayInput [min]="MIN_BIRTH_DATE"
        (click)="picker.open()" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker attr.data-selenium-id="mat-datepicker-birthdate" #picker startView="multi-year"
        [startAt]="birthDateStart" xPosition="end" yPosition="below">
      </mat-datepicker>
      <mat-error *ngIf="
                  bookingPreliminaryFormGroup.get('birthDate').errors &&
                  bookingPreliminaryFormGroup.get('birthDate').errors['datesInvalid']
                " [innerHTML]="'GLOBAL.DATE-ERROR-INVALID' | translate"></mat-error>
      <mat-error *ngIf="
                  bookingPreliminaryFormGroup.get('birthDate').errors &&
                  bookingPreliminaryFormGroup.get('birthDate').errors['dateIsNotBeforeOrSame']
                " [innerHTML]="'GLOBAL.DATE-ERROR-PAST' | translate"></mat-error>
      <mat-hint *ngIf="
                  birthDayInput.value &&
                  bookingPreliminaryFormGroup.get('birthDate').invalid &&
                  (!bookingPreliminaryFormGroup.get('birthDate').errors ||
                    !bookingPreliminaryFormGroup.get('birthDate').errors.length)
                " [innerHTML]="'GLOBAL.DATE-PLACEHOLDER' | translate"></mat-hint>
    </mat-form-field>
  </div>

</form>