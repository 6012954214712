import { generateObjectId } from '@arzt-direkt/wfa-generic-utils'

import { WfaForm } from './../interfaces-autogen-from-joi/wfa-form'
import { id2String } from './id-2-string'

export function wfaFormIdsToString<T extends WfaForm>(
  form: T,
): T & { _id: string } {
  const wfaFormVersionId = id2String(
    form.wfaFormVersionId ?? generateObjectId(),
  )
  const _id: string = id2String(form?._id ?? wfaFormVersionId)
  return {
    ...form,
    _id,
    instance: id2String(form?.instance),
    praxisId: id2String(form?.praxisId),
    wfaFormVersionId,
  }
}
