import Joi from 'joi'

import { Visibility } from '../../../interfaces-autogen-from-joi'

export const visibilityDict: Record<Visibility, Visibility> = {
  on: 'on',
  readOnlyIfWfaKey: 'readOnlyIfWfaKey',
  off: 'off',
  readOnly: 'readOnly',
} as const
export const visibilities = Object.values(visibilityDict)

export const VisibilityJoi = Joi.string()
  .valid(...visibilities)
  .description(
    '`readOnlyIfWfaKey` means it is readOnly (can not be edited) if a tomedo wfaKey is set.',
  )
  .meta({
    className: 'Visibility',
  })
  .example('off')
