import { SurveyCreatorModel } from 'survey-creator-core'

import { Routing } from '../../../interfaces'
import { StaticEditorEnv } from './../../../editor.env'
import { isAdisRouting } from './../../../interfaces'
import { addToolboxAction } from './add-toolbox-action'
import { deleteDraftUsingForm } from './delete-draft'
import { getWfaFormId } from './get-wfa-form-id'
import { navigateToEditorOrigin } from './navigate-to-editor-origin'
import { publishDraftUsingForm } from './publish-form'

export function customizeAfterFormLoadToolboxActions(
  creator: SurveyCreatorModel,
) {
  addToolboxAction(creator, {
    action: () => null,
    enabled: false,
    iconName: undefined,
    id: 'toolbox-action-edit-wfaFormId',
    index: 0,
    showTitle: true,
    title: getWfaFormId(creator.JSON),
    tooltip: 'Eindeutige Kennung des Formulars',
  })
}

export function customizeToolboxActions(
  creator: SurveyCreatorModel,
  routing: Routing<StaticEditorEnv>,
) {
  if (!isAdisRouting(routing)) {
    console.debug(
      '[customizeToolboxActions]: do not show publish / close / delete buttons...',
    )
    return
  }
  console.debug(
    '[customizeToolboxActions]: show publish / close / delete buttons...',
  )

  addToolboxAction(creator, {
    id: 'toolbox-action-publish-draft',
    iconName: 'icon-v2check_24x24',
    title: 'Entwurf veröffentlichen',
    action: () => publishDraftUsingForm(creator.JSON, routing).subscribe(),
    index: 4,
  })

  addToolboxAction(creator, {
    id: 'toolbox-action-close-draft',
    iconName: 'icon-close_16x16',
    title: 'Entwurf speichern und schließen (ohne zu veröffentlichen)',
    action: () => saveAndClose(creator, routing),
    index: 5,
  })

  addToolboxAction(creator, {
    id: 'toolbox-action-delete-draft',
    iconName: 'icon-delete',
    title: 'Entwurf verwerfen',
    action: () => deleteDraftUsingForm(creator.JSON, routing),
    index: 6,
  })
}

function saveAndClose(creator: SurveyCreatorModel, routing: Routing) {
  creator.saveSurveyFunc(0, () => {
    console.debug('[saveAndClose]: closing creator.')
    navigateToEditorOrigin(routing)
  })
}
