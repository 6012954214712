import { PropertyGridDict } from '@arzt-direkt/wfa-definitions'
import { allGridProperties as tsvLeq148 } from './tsv-leq-148'
import { allGridProperties as tsv149 } from './tsv-149'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'

/**
 * Returns grid property settings for the specified tomedo version
 *
 * With later tomedo versions, more and more settings are switched on.
 * When the setting is switched on, tomedo-server has to be able to
 * handle it properly. This is why these settings are tied to a
 * specific tomedo version.
 */
export function getAllGridProperties(
  tomedoVersion?: Maybe<number | string>,
): PropertyGridDict {
  try {
    const latest = tsv149
    if (tomedoVersion === 'latest') return latest

    const asNumber = Number(tomedoVersion)
    if (asNumber > 149) return latest
    if (asNumber === 149) return latest
    if (asNumber === 148) return tsvLeq148
  } catch (e) {
    console.info('[getAllGridProperties]: Could not parse tomedo version.')
  }

  return tsvLeq148
}
