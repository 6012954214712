import { CommonModule } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { setDocumentStyles } from '@arzt-direkt/colors'
import {
  CoreLocale,
  updateMetadata,
  assertWfaForm,
} from '@arzt-direkt/wfa-definitions'
import { joinWithSpace } from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, combineLatest, map } from 'rxjs'

import { localizeCreatorJson } from '../../editor/customization/creator-json-customization/localize-creator-json'
import { EditorComponent } from '../../editor/editor.component'
import { EditorEnv, initialEditorEnv } from '../../editor/editor.env'
import { LicenseTier } from '../../types/license-tier'
import { emitDefaultAndSanitize } from '../../utility/sanitize-control'
import {
  STATIC_EDITOR_ENV,
  StaticEditorEnv,
  supportEditorEnv,
} from './../../editor/editor.env'

@UntilDestroy()
@Component({
  selector: 'wfa-support-editor',
  standalone: true,
  imports: [CommonModule, EditorComponent, ReactiveFormsModule],
  templateUrl: './support-editor.component.html',
  styleUrls: ['./support-editor.component.scss'],
  providers: [{ provide: STATIC_EDITOR_ENV, useValue: supportEditorEnv }],
})
export class SupportEditorComponent implements OnInit {
  readonly locales = ['en', 'de', 'fr', 'it', 'es']
  readonly localeControl = new FormControl<CoreLocale>('de')
  readonly licenseTiers = ['basic', 'devEverything']
  readonly licenseTierControl = new FormControl<LicenseTier>('basic')
  jsonFilename = 'unnamed'
  uploadedJson!: object

  constructor(
    @Inject(STATIC_EDITOR_ENV) readonly staticEditorEnv: StaticEditorEnv,
  ) {}

  editorEnv$ = new BehaviorSubject<EditorEnv>(initialEditorEnv)
  requestWfaForm$ = new BehaviorSubject<boolean>(true)
  wfaFormRequested = false

  changeName(event: any) {
    this.jsonFilename = `${event.target.value}`
  }

  importJson(event: any) {
    const file: File = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (_e: any) => {
      if (typeof reader.result === 'string') {
        const maybeForm = JSON.parse(reader.result)
        localizeCreatorJson(maybeForm)
        this.uploadedJson = updateMetadata(maybeForm)
        this.jsonFilename = file.name.split('.json')[0]
        this.editorEnv$.next({
          ...this.editorEnv$.value,
          json: this.uploadedJson,
        })
        return
      }
    }

    reader.readAsText(file)
  }

  requestWfaForm() {
    this.wfaFormRequested = true
    this.requestWfaForm$.next(true)
  }

  downloadWfaForm(event: any) {
    if (this.wfaFormRequested === false) return
    localizeCreatorJson(event)

    try {
      assertWfaForm(event)
    } catch (err: unknown) {
      alert(
        joinWithSpace(
          `Das erstellte Formular ist nicht gültig: `,
          (err as Error).message,
        ),
      )
    }

    const fileContent = JSON.stringify(event)
    const filename = `${this.jsonFilename}.json`

    const blob = new Blob([fileContent], { type: 'application/json' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    this.wfaFormRequested = false
  }

  ngOnInit(): void {
    setDocumentStyles(document)

    combineLatest({
      locale: emitDefaultAndSanitize(this.localeControl, 'de'),
      licenseTier: emitDefaultAndSanitize(this.licenseTierControl, 'basic'),
    })
      .pipe(
        map(partialEnv => ({ ...partialEnv, origin: 'orphan' }) as EditorEnv),
        untilDestroyed(this),
      )
      .subscribe(this.editorEnv$)
  }
}
