import { InsuranceService } from '@a-d/insurance/insurance.service';
import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ArztType } from '@lib/entities/ArztType.entity';
import { InsuranceType } from '@lib/entities/Insurance.entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { UserFormattersService } from '../../formatters/user-formatters';
import { FormValidationHelpersService } from '../form-validation-helpers.service';
import FormValidators from '../form-validators.service';

@UntilDestroy()
@Component({
  selector: 'app-arzt-type-and-lanr-fields-component',
  host: { 'class': 'c-form__row' },
  template: `
  <ng-container [formGroup]="formGroup">

    <mat-form-field *ngIf="arztType.enabled" appearance="outline">
      <mat-label>{{ 'USER-DATA-ARZT.type' | translate}}</mat-label>
      <mat-select [formControlName]="arztTypeName" [required]="arztType.errors?.required">

        <mat-select-trigger style="display: flex">{{ userFormatter.arztType(arztType.value) }}
          <ng-content select="[insuranceBadges]"></ng-content>
        </mat-select-trigger>

        <mat-option class="mat-option--isMultiline" value="pa">
          <span>{{ 'USER-DATA-ARZT.private-doc' | translate}}</span>
          <span>{{ 'USER-DATA-ARZT.private-description' | translate}}</span>
        </mat-option>

        <mat-option class="mat-option--isMultiline" value="ka">
          <span>{{ 'USER-DATA-ARZT.panel-doc' | translate}}</span>
          <span>{{ 'USER-DATA-ARZT.panel-description' | translate}}</span>
        </mat-option>

      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="arztNumber.enabled" appearance="outline">
      <mat-label>{{ 'USER-DATA-ARZT.lanr' | translate}} ({{ arztNumberLocalizedName }})</mat-label>
      <input matInput [formControlName]="arztNumberName"
        [required]="arztNumber.errors?.required">
      <mat-error *ngIf="arztNumber.errors?.lanrTooShort">{{ 'USER-DATA-ARZT.error-lanr-short' | translate}}</mat-error>
      <mat-error *ngIf="arztNumber.errors?.lanrTooLong">{{ 'USER-DATA-ARZT.error-lanr-long' | translate}}</mat-error>
      <mat-error *ngIf="arztNumber.errors?.lanrDigitsOnly">{{ 'USER-DATA-ARZT.error-lanr-digits' | translate}}</mat-error>
      <mat-error *ngIf="arztNumber.errors?.lanrInvalid">{{ 'USER-DATA-ARZT.error-lanr-invalid' | translate}}</mat-error>
      <mat-error *ngIf="arztNumber.errors?.pattern">{{ 'USER-DATA-ARZT.error-number-invalid' | translate}}</mat-error>
    </mat-form-field>

  </ng-container>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, TranslateModule]
})
export class ArztTypeAndLanrFieldsComponent {

  @Input() formGroup: UntypedFormGroup
  @Input() arztTypeName = 'arztType'
  @Input() arztNumberName = 'arztNumber'
  @Input() country: UntypedFormControl
  @Input() isNonArztDialect: boolean

  public get arztType(): AbstractControl { return this.formGroup.get(this.arztTypeName) }
  public get arztNumber(): AbstractControl { return this.formGroup.get(this.arztNumberName) }

  public get allInsurancesTreatedByArztType(): InsuranceType[] {
    return this.insuranceService.allInsurancesTreatedByArztType(this.arztType.value)
      .filter((type) => type !== InsuranceType.None)
  }

  public arztNumberLocalizedName: string
  public isGermanPraxis: boolean
  public isSwissPraxis: boolean

  constructor(
    public userFormatter: UserFormattersService,
    private formValidationHelpers: FormValidationHelpersService,
    private insuranceService: InsuranceService,
  ) { }

  ngOnInit() {
    merge(
      this.arztType.valueChanges,
      this.country.valueChanges,
    ).pipe(
      startWith(0),
      untilDestroyed(this),
    ).subscribe(() => {
      this.updateFieldsAndValidation()
    })
  }

  ngOnChanges() {
    this.updateFieldsAndValidation()
  }

  private updateFieldsAndValidation() {
    this.isGermanPraxis = (this.country.value || 'deutschland').toLowerCase() === 'deutschland'
    this.isSwissPraxis = this.country.value.toLowerCase() === 'schweiz'
    this.arztNumberLocalizedName = this.userFormatter.arztNumberLocalizedName(this.country.value)

    // arztType
    // HACK: Setting arzt-type for non-german users and if mitarbeiter-dialect is turned on to KA (Kassenarzt).
    //       So they are able to have sessions with all kind of patients (insurances).
    // const countryTouchedAndInvalid = this.country.touched && this.country.invalid
    const arztTypeIsShown = !this.isNonArztDialect && (!this.isSwissPraxis || this.country.invalid)
    this.formValidationHelpers.setStatusIf(this.arztType, arztTypeIsShown)
    if (!arztTypeIsShown) {
      this.arztType.setValue(ArztType.Kassenarzt, { emitEvent: false })
    }

    // arztNumber
    const arztNumberIsShown = this.arztType.value === ArztType.Kassenarzt && !this.isNonArztDialect
    if (arztNumberIsShown) {
      switch (this.country.value.toLowerCase()) {
        case 'schweiz': this.arztNumber.setValidators([/*Validators.pattern('[A-Z][0-9]{6}')*/]); break;
        case 'österreich': this.arztNumber.setValidators([Validators.required, Validators.pattern('[0-9]{3,5}-[0-9]{2}')]); break;
        default: this.arztNumber.setValidators([Validators.required, FormValidators.lanrValidator]); break;
      }
      this.arztNumber.enable({ emitEvent: false })
      this.arztNumber.markAsTouched()
    } else {
      this.arztNumber.clearValidators()
      this.arztNumber.disable({ emitEvent: false })
      this.arztNumber.setValue(null, { emitEvent: false })
    }

    this.formValidationHelpers.updateValueAndValidity([this.arztType, this.arztNumber])
  }

}
