import { LoadableLinkType } from './../adis/viewer-from-link/single-or-multilink/single-or-multilink.state'
import { replaceSpecialCharacters } from './../utility/replace-special-characters'
import { MagicFillComponent } from './../utility-components/magic-fill/magic-fill.component'
import { addCustomNavigationButtonCompleteForMultilink } from './setup/add-custom-navigation-button-complete-for-multilink'
import { toObservable } from '@angular/core/rxjs-interop'
import { SingleOrMultilinkStore } from './../adis/viewer-from-link/single-or-multilink/single-or-multilink.store'
import { LanguagePickerService } from './../utility-components/language-picker/language-picker.service'
import { LanguagePickerComponent } from './../utility-components/language-picker/language-picker.component'
import 'survey-core/survey.i18n'

import { CommonModule } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import {
  defaultAdColors,
  defaultTomedoColors,
  setDocumentStyles,
} from '@arzt-direkt/colors'
import {
  DisplayOption,
  FormResponseData,
  FormResponseOrigin,
  IsEmbeddedIn,
  ViewerEnvState,
  WfaForm,
  WfaFormResponse,
  WfaFormWithResponse,
} from '@arzt-direkt/wfa-definitions'
import {
  asString,
  mapValues,
  isNil,
  nonNil,
  Nullable,
  isEqual,
  get,
} from '@arzt-direkt/wfa-generic-utils'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Observer,
  of,
  take,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs'
import { SurveyModule } from 'survey-angular-ui'
import { Model } from 'survey-core'

import { setSurveyConfiguration } from './setup/set-survey-configuration'
import { updateSurveyModelWithEnvState } from './setup/update-survey-model-with-env-state'
import { exportSurveyToPdf } from './surveyjs-pdf-export/export-survey-to-pdf'
import { ViewerStore } from './viewer.store'
import { WfaEnvService } from '../../environments/wfa-env.service'

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'wfa-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: [
    './viewer.component.scss',
    '../../scss/mat-card.scss',
    '../../scss/button.scss',
  ],
  providers: [ViewerStore],
  imports: [
    CommonModule,
    LanguagePickerComponent,
    MagicFillComponent,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    ReactiveFormsModule,
    SurveyModule,
    TranslateModule,
  ],
})
export class ViewerComponent implements OnInit {
  /**
   * Use `form$`, `formResponse$`, `envState$` (`SingleOrMultilinkStore`) to
   * communicate `ViewerComponent` consumer's state to `ViewerComponent`
   * (Preload answers, change environment or form, etc).
   *
   * Use `viewerStore.form$`, `viewerStore.formResponse$` and `viewerStore.envState$`
   * to store communicate `ViewerComponent`'s state to its consumer.
   * (Submit answers etc).
   */
  @Input() form$ = new Observable<Nullable<WfaForm>>()
  @Input() formResponse$ = new Observable<Nullable<WfaFormResponse>>()
  @Input() envState$ = new Observable<ViewerEnvState>()
  @Input() readOnly = false
  @Input() requestResponse$ = new BehaviorSubject<boolean>(false)
  @Output() formResponseDataEmitter = new EventEmitter<FormResponseData>()

  title: string | undefined
  surveyModel: Model = new Model()
  isEmbeddedIn: IsEmbeddedIn = 'arztDirekt'
  linkType: LoadableLinkType = 'loading'
  displayOption: DisplayOption = 'editable'
  submittedAt: Nullable<number>
  completed = false

  constructor(
    private cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly viewerStore: ViewerStore,
    private dialog: MatDialog,
    private languagePicker: LanguagePickerService,
    private store: SingleOrMultilinkStore,
    public wfaEnv: WfaEnvService,
  ) {
    this.languagePicker.onLocaleChange = (lang: string) =>
      this.viewerStore.setLocale(lang)
  }

  ngOnInit(): void {
    this.store.state$.pipe(take(1)).subscribe((state) => {
      setSurveyConfiguration(this.surveyModel, this.dialog)
    })

    this.store.nextFwr$
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev?.formResponse.responseId === curr?.formResponse.responseId,
        ),
        withLatestFrom(this.store.isMultilink$),
        untilDestroyed(this),
      )
      .subscribe(([nextFwr, isMultilink]) => {})

    this.store.isMultilink$
      .pipe(
        filter((isMultilink) => isMultilink !== true),
        take(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.surveyModel.onComplete.add(() => {
          this.emitFormResponseData('completeButton')
          this.completed = true
        })
      })

    this.surveyModel.onValueChanged.add(() => this.emitFormResponseData('blur'))
    this.form$.pipe(filter(nonNil)).subscribe((f) => {
      this.viewerStore.setForm(f)
      this.title =
        asString(f.title.en ?? f.title.de ?? Object.values(f.title)[0]) ??
        'Survey title'
    })

    // preload responses from parent / SingleOrMultilink store
    this.formResponse$
      .pipe(
        withLatestFrom(this.viewerStore.formResponse$),
        filter(([parentResponse, viewerStoreResponse]) => {
          return !isEqual(parentResponse, viewerStoreResponse)
        }),
        map(([parentResponse, _]) => parentResponse),
        filter(nonNil),
        untilDestroyed(this),
      )
      .subscribe((formResponse: WfaFormResponse) => {
        this.submittedAt = formResponse?.submittedAt
        this.viewerStore.setFormResponse(formResponse)
      })

    this.envState$.pipe(untilDestroyed(this)).subscribe((env) => {
      this.viewerStore.setEnv(env)
      this.isEmbeddedIn = env.isEmbeddedIn
      this.displayOption = env.displayOption
      updateSurveyModelWithEnvState(this.surveyModel, env, this.dialog)

      updateDocumentStyles(this.isEmbeddedIn)
    })

    this.viewerStore.locale$.subscribe((locale) => {
      this.surveyModel.locale = locale
    })

    // determine if the form can be skipped altogether without filling out
    this.viewerStore.form$.subscribe((form) => {
      this.surveyModel.fromJSON(form)
      this.emitFormResponseData('validateOnRender')
    })

    // determine if the form can be skipped altogether without filling out
    this.viewerStore.formResponse$
      .pipe(filter(nonNil), withLatestFrom(this.store.visited$))
      .subscribe(([formResponse, visited]) => {
        this.surveyModel.data = formResponse?.surveyjsResponse
        this.emitFormResponseData('validateOnRender')
      })

    this.requestResponse$
      .pipe(
        filter((v) => v === true),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.emitFormResponseData(<FormResponseOrigin>'requestResponse')
      })

    this.store.activeResponseId$
      .pipe(
        withLatestFrom(
          this.store.visited$,
          this.store.isMultilink$,
          this.store.nextFwr$,
        ),
        untilDestroyed(this),
      )
      .subscribe(([responseId, visited, isMultilink, nextFwr]) => {
        if (!isMultilink) return

        // update next form button
        addCustomNavigationButtonCompleteForMultilink(
          this.surveyModel,
          this.store,
          isNil(nextFwr),
        )

        // validate form if already visited
        const rId = responseId?.toString()
        if (nonNil(rId) && get(visited, rId) === true) {
          // Give the survey model time to properly initialize and render
          setTimeout(() => {
            this.surveyModel.validate(true) // Force UI update with true parameter
            this.cd.detectChanges() // Trigger change detection
          })
        }
      })
  }
  count = 0

  constructFormResponseData(
    origin: FormResponseOrigin,
  ): Observable<FormResponseData> {
    return of(null).pipe(
      withLatestFrom(this.form$, this.formResponse$),
      map(([_, form, formResponse]) => ({
        form,
        formResponse,
      })),
      filter(({ form }) => nonNil(form)),
      filter(({ formResponse }) => nonNil(formResponse)),
      map((data) => data as { form: WfaForm; formResponse: WfaFormResponse }),
      take(1),
      map(({ form, formResponse }) => {
        const submittedAt = origin === 'completeButton' ? Date.now() : undefined
        if (nonNil(submittedAt)) this.submittedAt = submittedAt
        if (origin !== 'validateOnRender') {
          this.store.setVisited({
            responseId: formResponse.responseId,
            visited: true,
          })
        }

        const formResponseData: WfaFormWithResponse & FormResponseData = {
          formIsValid: checkFormValidity(this.surveyModel),
          form,
          formResponse: {
            responseId: formResponse?.responseId,
            wfaFormId: form.wfaFormId,
            wfaFormVersionId: form.wfaFormVersionId,
            patientId: formResponse.patientId,
            praxisId: form.praxisId ?? formResponse.praxisId,
            surveyjsResponse: replaceSpecialCharacters(
              this.surveyModel.data,
              this.isEmbeddedIn,
            ),
            origin,
            submittedAt,
          },
        }
        return formResponseData
      }),
    )
  }

  emitFormResponseData(origin: FormResponseOrigin): void {
    this.constructFormResponseData(origin).subscribe((formResponseData) => {
      this.store.setValid({
        responseId: formResponseData.formResponse.responseId,
        isValid: formResponseData.formIsValid,
      })
      this.formResponseDataEmitter.emit(formResponseData)
    })
  }

  exportPdf = () => exportSurveyToPdf(this.surveyModel, this.submittedAt)

  completeForm = () => {
    this.constructFormResponseData(
      <FormResponseOrigin>'completeButton',
    ).subscribe((formResponseData) => {
      this.formResponseDataEmitter.emit(formResponseData)
      this.surveyModel.completeLastPage()
    })
  }

  subscribe = (obs: Observable<unknown>, func: Partial<Observer<unknown>>) => {
    return obs.pipe(untilDestroyed(this)).subscribe(func)
  }
}

function checkFormValidity(surveyModel: Model): boolean {
  return surveyModel.validate(false)
}

function updateDocumentStyles(isEmbeddedIn: IsEmbeddedIn) {
  if (isEmbeddedIn === 'macOsTomedo')
    setDocumentStyles(document, defaultTomedoColors)
  if (isEmbeddedIn === 'iOsTomedo')
    setDocumentStyles(document, defaultTomedoColors)
  if (isEmbeddedIn === 'arztDirekt')
    setDocumentStyles(document, defaultAdColors())
}
