import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OTKUser } from '../entities/Calendar.entity';


/**
 * Service that provides access to the OTKUser, either as variable `otkUser`
 * or as Observable<OTKUser> `dynamicOtkUser`.
 */
@Injectable({
  providedIn: 'root'
})
export class OtkUserService {

  //private _otkUser: OTKUser = null
  private _otkUser$: BehaviorSubject<OTKUser> = new BehaviorSubject<OTKUser>(null)

  public get otkUser(): null | OTKUser { 
    return this._otkUser$.value; 
  }
  public get dynamicOtkUser$(): Observable<OTKUser> {
    return this._otkUser$.asObservable();
  }

  public set otkUser(otkUser: OTKUser) {
    this._otkUser$.next(otkUser);
  }

  constructor(
    public httpClient: HttpClient,
    private logger: NGXLogger
  ) { }

  /**
   * Load a user from the database, should only be done by the OtkUserGuard!
   */
  public loadOtkUser(instanceId: string): Observable<boolean> {
    const queryParams = new HttpParams().set('instance', instanceId);

    return this.httpClient.post(
      `${environment.otkUrl}/api/otkuser/allSettings`,
      {},
      { params: queryParams }
    ).pipe(
      map((response: { status: number, message: string, user: OTKUser }) => {
        if (!response || response.status !== 200 || !response.user) {
          throw { name: 'NoOTKUserReceived', error: 'Received empty response', raw: response };
        }
        this._otkUser$.next(response.user);
        this.logger.info("Successfully fetched otkuser (OtkUserService)");
        this.logger.debug("OTKUser: ", this.otkUser);
        return true;
      })
    )
  }

  /**
   * Update an OTKUser in the database.
   * @param otkUser - object specifying the properties to be updated
   */
  updateOtkUser(instanceId: string, userId: string, otkUser: Partial<OTKUser>): Observable<OTKUser> {
    const updates = { ...otkUser, instance: instanceId, _id: userId }
    return this.httpClient.put(`${environment.otkUrl}/api/otkuser/update`, updates, { withCredentials: true }).pipe(
      map((response: { status: number, message: string, user: OTKUser }) => {
        if (!response || response.status !== 200 || !response.user) {
          throw { name: 'OTKUserUpdateError', error: response.message || 'Updating OTKUser not successful' };
        }
        this._otkUser$.next(response.user);
        this.logger.info("Successfully updated otkuser (OtkUserService)");
        this.logger.debug("OTKUser: ", this.otkUser);
        return response.user;
      })
    )
  }



}
