import { validateWfaForm, WfaForm } from '@arzt-direkt/wfa-definitions'
import { WfaFormDraftUpdateQueryResultJoi } from '@arzt-direkt/wfa-definitions'
import { validate } from '@arzt-direkt/wfa-generic-utils'
import { isWfaMessage } from '@arzt-direkt/wfa-generic-utils'
import { cloneDeep } from '@arzt-direkt/wfa-generic-utils'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SurveyCreatorModel } from 'survey-creator-core'

import { customizeCreatorJson } from './customization/creator-json-customization/customize-creator-json'
import { EditorDataService } from './editor.data-service'
import { EditorStore } from './editor.store'
import { WfaEnv } from '../../environments/wfa-env'

// see https://surveyjs.io/survey-creator/documentation/get-started-angular
// section `Save and Load Survey Model Schemas`
type Callback = (
  currentChangeNumber: number,
  serverHasAppliedChange: boolean,
) => unknown

export function saveToStoreAndDb(
  store: EditorStore,
  creator: SurveyCreatorModel,
  editorDataService: EditorDataService,
  wfaEnv: WfaEnv,
) {
  return (saveNo: number, callback: Callback) => {
    const json = addWfaData(cloneDeep(creator.JSON)) as WfaForm
    customizeCreatorJson(json)
    store.setForm(json)

    const wfaForm = validateWfaForm(json)
    if (isWfaMessage(wfaForm)) {
      if (wfaForm.message.includes('names within the form must be unique')) {
        wfaEnv.notification.error([
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-ERROR`,
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-NAME-UNIQUE`,
        ])
      } else {
        wfaEnv.notification.error([
          `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.VALIDATE-WFA-FORM-ERROR`,
          wfaForm.message,
        ])
      }

      console.error(`Invalid wfaForm: ${wfaForm.message}`, json)
      callback(saveNo, false)
      return
    }
    store.setFormIsValid(wfaForm)

    editorDataService
      .wfaUpdateWfaFormDraft(wfaForm)
      .pipe(
        catchError((err) => {
          wfaEnv.notification.error([
            `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.HTTP-CONNECTION-ERROR`,
          ])
          console.error('HTTP request error:', err)
          callback(saveNo, false)
          return of({ message: 'Error occurred, fallback data' })
        }),
      )
      .subscribe((res: any) => {
        const maybeRes = validate(
          WfaFormDraftUpdateQueryResultJoi,
          res?.queryResult,
        )
        if (isWfaMessage(maybeRes)) {
          wfaEnv.notification.error([
            `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.HTTP-RESPONSE-ERROR`,
          ])
          console.error('HTTP result mismatch type: ', maybeRes.message)
          callback(saveNo, false)
        }

        callback(saveNo, true)
      })
  }
}

function addWfaData(maybeForm: Partial<WfaForm>): WfaForm {
  return {
    ...maybeForm,
    _id: maybeForm.wfaFormVersionId,
    status: 'live',
  } as unknown as WfaForm
}
