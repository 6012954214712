import { CommonModule } from '@angular/common'
import { Component, Input, signal, Signal } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'wfa-spinner',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  styleUrls: ['./../../../scss/base/_overlay.scss', './spinner.component.scss'],
  template: `
    @if (showSpinner()) {
      <div class="overlay" data-selenium-id="overlay-@spinner/overlay">
        <mat-spinner></mat-spinner>
        @if (message) {
          <div class="message">{{ message | translate }}</div>
        }
      </div>
    }
  `,
})
export class SpinnerComponent {
  @Input({ required: true }) showSpinner!: Signal<boolean>
  @Input() message?: string
  constructor(private translateService: TranslateService) {}
}
