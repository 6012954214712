import { TomedoVersion } from '@arzt-direkt/wfa-definitions'
import { SurveyCreatorModel } from 'survey-creator-core'

import { addCustomEditorProperties } from '../../../shared-surveyjs/property-customization/custom-properties/add-custom-properties'
import { customizeSurveyjsProperties } from '../../../shared-surveyjs/property-customization/surveyjs-properties/customize-surveyjs-properties'
import { applyChangedProperties } from './change-properties/apply-changed-properties'
import { handlePropertyGridOnElementChange } from './handle-property-grid-on-element-change'
import { addHtmlEditorToPropertyGrid } from './html-editor/add-html-editor-to-property-grid'
import { updatePropertiesOnQuestionChange } from './update-properties-on-question-change'
import { updatePropertiesOnValueChange } from './update-properties-on-value-change/update-properties-on-value-change'
import { validatePropertiesOnValueChange } from './validate-properties-on-value-change/validate-properties-on-value-change'
import { updatePropertiesOnItemValueDeletion } from './update-properties-on-item-value-deletion'

export function customizePropertyGrid(
  creator: SurveyCreatorModel,
  tomedoVersion?: TomedoVersion,
): void {
  applyChangedProperties(tomedoVersion)
  addCustomEditorProperties(tomedoVersion)
  customizeSurveyjsProperties()
  updatePropertiesOnValueChange(creator)
  updatePropertiesOnQuestionChange(creator)
  updatePropertiesOnItemValueDeletion(creator)
  validatePropertiesOnValueChange(creator)
  handlePropertyGridOnElementChange(creator)
  addHtmlEditorToPropertyGrid(creator)
}
