import { dynamicallyRequired } from './../../utility/validators/dynamically-required'
import { Injectable } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { WfaFormDraftInfo, WfaFormInfo } from '@arzt-direkt/wfa-definitions'
import { PraxisDetails } from '@arzt-direkt/wfa-definitions'
import { PraxisId } from '@arzt-direkt/wfa-definitions'
import { WfaFormId } from '@arzt-direkt/wfa-definitions'
import { wfaFormIdRegex } from '@arzt-direkt/wfa-definitions'
import { Maybe } from '@arzt-direkt/wfa-generic-utils'
import { nullish } from '@arzt-direkt/wfa-generic-utils'
import { createArrayUnion } from '@arzt-direkt/wfa-generic-utils'
import { BehaviorSubject, filter, map, Observable, Subject, take } from 'rxjs'

import { controlIncludesStringFromArray } from './../../utility/validators/control-includes-string-from-array'
import { MatInputPopupParams } from './../../utility-components/mat-input-popup.component'
import { MatInputPopupUserInput } from './../../utility-components/mat-input-popup.component'
import { FormAndDraftInfos } from './zs-support-comms.service'

const defaultWfaFormIdValidators = [
  Validators.pattern(wfaFormIdRegex),
  dynamicallyRequired,
]

@Injectable({
  providedIn: null,
})
export class ZsSupportService {
  draftInfos$ = new BehaviorSubject<WfaFormDraftInfo[]>([])
  formInfos$ = new BehaviorSubject<WfaFormInfo[]>([])
  praxisDetails$ = new BehaviorSubject<PraxisDetails | undefined>(undefined)
  showSpinner$ = new BehaviorSubject<boolean>(false)

  editWfaFormIdModal: MatInputPopupParams = {
    matInputControl: new FormControl<string>('', defaultWfaFormIdValidators),
    matInputPopupI18n: {
      editInput: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.EDIT-INPUT',
      label: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.LABEL',
      save: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.SAVE',
      cancel: 'WFA.MAT-INPUT-POPUP.EDIT-WFA-FORM-ID.CANCEL',
    },
    show$: new BehaviorSubject<boolean>(false),
    userInput$: new Subject<MatInputPopupUserInput>(),
  }

  getPraxisId(origin = 'ZsSupportService'): Maybe<PraxisId> {
    const praxisId = this.praxisDetails$.value?._id
    if (nullish(praxisId)) {
      console.error(`[${origin}]: praxisId is undefined`)
      return undefined
    }
    return praxisId
  }

  setWfaFormId(id: WfaFormId) {
    this.editWfaFormIdModal.matInputControl.setValue(id)
  }
}

export function updateInfos(zsSupportService: ZsSupportService) {
  return (infos: FormAndDraftInfos) => {
    zsSupportService.formInfos$.next(infos.formInfos)
    zsSupportService.draftInfos$.next(infos.draftInfos)

    const wfaFormIds = createArrayUnion(
      infos.draftInfos.map((di) => di.wfaFormId),
      infos.formInfos.map((fi) => fi.wfaFormId),
    )
    prohibitDuplicates(
      zsSupportService.editWfaFormIdModal.matInputControl,
      wfaFormIds,
    )
  }
}

/**
 * Adds validators to a FormControl to prohibit duplicate `WfaFormId`s.
 *
 * @param {FormControl} control - The FormControl to modify. This function mutates the control.
 * @param {WfaFormId[]} wfaFormIds - Array of existing `WfaFormId`s to check against for duplicates.
 * @throws {Error} If the control or wfaFormIds array is null or undefined.
 *
 * @example
 * const control = new FormControl('');
 * const existingIds = ['form1', 'form2'];
 * prohibitDuplicates(control, existingIds);
 */
function prohibitDuplicates(control: FormControl, wfaFormIds: WfaFormId[]) {
  control.setValidators([
    ...defaultWfaFormIdValidators,
    controlIncludesStringFromArray(wfaFormIds),
  ])
  control.updateValueAndValidity()
}

export function openEditWfaFormIdModal(
  editWfaFormIdModal: MatInputPopupParams,
): Observable<string> {
  editWfaFormIdModal.show$.next(true)

  return editWfaFormIdModal.userInput$.pipe(
    take(1),
    filter((input: MatInputPopupUserInput) => input === 'save'),
    map(() => editWfaFormIdModal.matInputControl.value),
  )
}
