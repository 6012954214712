import { Id, WfaForm } from '@arzt-direkt/wfa-definitions'
import {
  Nullable,
  isNil,
  ValidationError,
  joinWithSpace,
} from '@arzt-direkt/wfa-generic-utils'
import { catchError, of } from 'rxjs'

import { Routing } from '../../../interfaces'
import { getUrl } from './../../../../utility/get-url'
import { httpOptions } from './../../../../utility/http-params'
import { AreYouSureUserInput } from './../../../../utility-components/are-you-sure.component'
import { AdisEditorEnv } from './../../../editor.env'
import { navigateToEditorOrigin } from './navigate-to-editor-origin'

export function deleteDraftUsingForm(
  wfaForm: Nullable<WfaForm>,
  routing: Routing<AdisEditorEnv>,
) {
  if (isNil(wfaForm)) {
    console.error(`[publishDraft]: isNil wfaFormVersionId`)
    return
  }
  deleteDraft(wfaForm.wfaFormVersionId, routing)
}

function deleteDraft(wfaFormVersionId: Id, routing: Routing<AdisEditorEnv>) {
  routing.staticEditorEnv.deleteDraftModal.userInput$.subscribe(
    (input: AreYouSureUserInput) => {
      if (input !== 'yes') {
        routing.staticEditorEnv.deleteDraftModal.show$.next(false)
      }

      const url = getUrl(routing.wfaEnvService, `wfaFormDraft/delete`)
      const body = {
        wfaFormVersionId,
      }

      routing.httpClient
        .post(url, body, httpOptions)
        .pipe(
          catchError(error => {
            routing.wfaEnvService.logger.error(
              `[deleteDraft]: called post with url ${url}, got error `,
              error,
              `for wfaFormVersionId`,
              wfaFormVersionId,
            )
            throw new ValidationError(
              joinWithSpace('[deleteDraft]:', error?.message),
            )
          }),
        )
        .subscribe((res: unknown) => {
          navigateToEditorOrigin(routing)
        })
    },
  )

  routing.staticEditorEnv.deleteDraftModal.show$.next(true)
}
