import Joi from 'joi'

/**
 * Extracts the valid type from a Joi schema that has a required 'type' field with a single allowed value
 * @param schema Joi schema with a 'type' field constraint
 * @returns The allowed type value, or undefined if not found
 */
export function getSchemaType(schema: Joi.Schema): string | undefined {
  try {
    const type = schema.extract('type')?.describe().allow?.[0]
    return typeof type === 'string' ? type : undefined
  } catch (err: unknown) {
    return undefined
  }
}
