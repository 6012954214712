import { allGridProperties as defaultSettings } from './default-settings'
import { mergeDeep } from '@arzt-direkt/wfa-generic-utils'
import { visibilityDict, Visibility } from '@arzt-direkt/wfa-definitions'

function setGeneralName(name: Visibility = visibilityDict.readOnlyIfWfaKey) {
  return {
    general: {
      name,
    },
  }
}

const redefinedProperties = {
  survey: setGeneralName('on'),
  page: setGeneralName('on'),
  text: setGeneralName(),
  comment: setGeneralName(),
  radiogroup: setGeneralName(),
  checkbox: setGeneralName(),
  dropdown: setGeneralName(),
  tagbox: setGeneralName(),
  html: setGeneralName(),
  signaturepad: setGeneralName(),
  boolean: setGeneralName(),
  image: setGeneralName(),
  imagepicker: setGeneralName(),
  matrix: setGeneralName(),
  matrixdropdown: setGeneralName(),
  matrixdynamic: setGeneralName(),
  multipletext: setGeneralName(),
  panel: setGeneralName(),
  paneldynamic: setGeneralName(),
  ranking: setGeneralName(),
  rating: setGeneralName(),
  file: setGeneralName(),
  'academic-title': setGeneralName(),
  birthdate: setGeneralName(),
  contact: setGeneralName(),
  country: setGeneralName(),
  gender: setGeneralName(),
  'sending-information': setGeneralName(),
}

/**
 * Settings for tomedo server version less or equal 148
 */
export const allGridProperties = mergeDeep(defaultSettings, redefinedProperties)
