import {
  CategoryDict,
  PropertyDict,
  PropertyGridDict,
  SurveyElementName,
  visibilities,
  Visibility,
  visibilityDict,
} from '@arzt-direkt/wfa-definitions'

import { PropertyState } from './property-state'

export function getChangedProperties(
  gridProperties: PropertyGridDict,
): Record<SurveyElementName, PropertyState[]> {
  const changedElDict = {}

  Object.entries(gridProperties).forEach(([surveyElementName, dict]) => {
    const elementHasCategories = hasElementCategories(
      dict as CategoryDict | PropertyDict,
    )
    const changedElementProperties = elementHasCategories
      ? getChangedPropertiesFromCategoryDict(dict as CategoryDict)
      : getChangedPropertiesFromPropertyDict(dict as PropertyDict)
    if (changedElementProperties.length > 0)
      Object.assign(changedElDict, {
        [surveyElementName]: changedElementProperties,
      })
  })

  return changedElDict as Record<SurveyElementName, PropertyState[]>
}

function hasElementCategories(dict: CategoryDict | PropertyDict): boolean {
  const dictValues = Object.values(dict)
  if (dictValues.length === 0) return false
  if (
    typeof dictValues[0] === 'string' &&
    visibilities.includes(dictValues[0] as Visibility)
  )
    return false
  return true
}

function getChangedPropertiesFromCategoryDict(
  categoryDict: CategoryDict,
): PropertyState[] {
  return Object.values(categoryDict)
    .map(propertyDict =>
      getChangedPropertiesFromPropertyDict(propertyDict as PropertyDict),
    )
    .flat()
}

function getChangedPropertiesFromPropertyDict(
  propertyDict: PropertyDict,
): PropertyState[] {
  return Object.entries(propertyDict)
    .filter(([_, value]) =>
      [
        visibilityDict.readOnlyIfWfaKey,
        visibilityDict.off,
        visibilityDict.readOnly,
      ].includes(value as Visibility),
    )
    .map(([key, value]) => ({ [key]: value })) as PropertyState[]
}
