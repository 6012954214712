<ng-container [formGroup]="formGroup">

  <div class="c-form__row__item--stacked">
    <div class="c-form__row__item--text" [class.no-max-width]="otk">

      <!-- Title & Description -->
      <ng-content></ng-content>

      <!-- (Hidden) Upload-Input -->
      <input attr.data-selenium-id="input-upload-hidden" #assetInput type="file" [accept]="acceptedMediaTypes"
        (change)="$event && $event.target && onFilesChange($event.target.files)" [style.display]="'none'"
        [multiple]="settings.multiple">

      <!-- Actions -->
      <div class="c-button-group">
        <button attr.data-selenium-id="button-upload" *ngIf="!asset.value" mat-flat-button (click)="assetInput.click()"
          [disabled]="isLoading || disabled">
          <mat-icon>upload</mat-icon>
          <span style="font-weight: 600;">
             {{ 'ASSET-UPLOAD-FIELD.UPLOAD-FILES' | translate }}
          </span>
        </button>
        <button attr.data-selenium-id="button-open-webcam-photo-dialog"
          *ngIf="!asset.value && settings.allowWebcamPhoto" mat-flat-button (click)="openWebcamPhotoDialog()"
          [disabled]="isLoading || disabled">
          <mat-icon>photo_camera</mat-icon>
          <span style="font-weight: 600;">
             {{ 'ASSET-UPLOAD-FIELD.TAKE-PHOTO' | translate }}
          </span>
        </button>

        <button attr.data-selenium-id="button-upload-new" *ngIf="asset.value && !settings.hideReuploadActions"
          mat-flat-button (click)="assetInput.click()" [disabled]="isLoading || disabled">
          <mat-icon>upload</mat-icon>
          <span>
             {{ 'ASSET-UPLOAD-FIELD.UPLOAD-NEW-FILES' | translate }}
          </span>
        </button>
        <button attr.data-selenium-id="button-open-webcam-photo-dialog-new"
          *ngIf="asset.value && settings.allowWebcamPhoto && !settings.hideReuploadActions" mat-flat-button
          (click)="openWebcamPhotoDialog()" [disabled]="isLoading || disabled">
          <fa-icon [icon]="['fas', 'camera']"></fa-icon>
          <span>
             {{ 'ASSET-UPLOAD-FIELD.TAKE-NEW-PHOTO' | translate }}
          </span>
        </button>

        <button attr.data-selenium-id="button-remove" *ngIf="asset.value" mat-flat-button color="warn"
          (click)="assetInput.value = null; deleteAsset()" [disabled]="isLoading || disabled">
          <mat-icon>delete</mat-icon>
          <span> {{ 'GLOBAL.REMOVE' | translate }}</span>
        </button>

        <mat-spinner *ngIf="isLoading" color="primary" [diameter]="20" style="margin-left:1rem;"></mat-spinner>
      </div>

      <!-- Errors -->
      <strong>
        <mat-error *ngIf="asset.errors && asset.errors['invalid']">
          {{ 'ASSET-UPLOAD-FIELD.ERR-FILE-DAMAGED' | translate }}
        </mat-error>
        <mat-error *ngIf="asset.errors && asset.errors['wrongType']">
          {{ 'ASSET-UPLOAD-FIELD.ERR-FILE-FORMAT' | translate:{ value:asset.errors['wrongType'].mediaType } }}
        </mat-error>
        <mat-error *ngIf="asset.errors && asset.errors['imageTooSmall']">
          {{ 'ASSET-UPLOAD-FIELD.ERR-TOO-SMALL' | translate:{ value:asset.errors['imageTooSmall'].sizeFormatted } }}
        </mat-error>
        <mat-error *ngIf="asset.errors && asset.errors['imageTooLarge']">
          {{ 'ASSET-UPLOAD-FIELD.ERR-TOO-BIG' | translate:{ value:asset.errors['imageTooLarge'].sizeFormatted } }}
        </mat-error>
      </strong>

    </div>
  </div>

  <!-- Image-Preview -->
  <div *ngIf="assetDataUriSanitized || !settings.hidePlaceholder"
    class="c-form__row__item--image {{ settings.imageClass }}">
    <img *ngIf="assetDataUriSanitized" [src]="assetDataUriSanitized" loading="lazy">
    <fa-icon *ngIf="!assetDataUriSanitized" [icon]="['fal', settings.placeholderIcon]"
      [size]="settings.placeholderIconSize"></fa-icon>
  </div>

</ng-container>