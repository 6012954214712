import { MatSnackBarModule } from '@angular/material/snack-bar'
import { NotificationService } from './../utility-components/notification/notification.service'
import { Component } from '@angular/core'

@Component({
  selector: 'app-test-notification',
  standalone: true,
  template: `
    <button (click)="showLogNotification()">Show Log</button>
    <button (click)="showWarnNotification()">Show Warning</button>
    <button (click)="showErrorNotification()">Show Error</button>
  `,
})
export class TestNotificationComponent {
  constructor(private notificationService: NotificationService) {}

  showLogNotification() {
    this.notificationService.log('This is a log message')
  }

  showWarnNotification() {
    this.notificationService.warn('This is a warning message')
  }

  showErrorNotification() {
    this.notificationService.error('This is an error message')
  }
}
