import { updateMetadata, WfaForm } from '@arzt-direkt/wfa-definitions'
import {
  FormPublishError,
  Nullable,
  isNil,
  NilError,
} from '@arzt-direkt/wfa-generic-utils'
import { take, timeout, tap, catchError, of, Observable } from 'rxjs'

import { Routing } from '../../../interfaces'
import { getUrl } from './../../../../utility/get-url'
import { httpOptions } from './../../../../utility/http-params'
import { AdisEditorEnv } from './../../../editor.env'
import { customizeCreatorJson } from './../../creator-json-customization/customize-creator-json'
import { navigateToEditorOrigin } from './navigate-to-editor-origin'

function publishForm(
  wfaForm: WfaForm,
  routing: Routing<AdisEditorEnv>,
): Observable<void> {
  const url = getUrl(routing.wfaEnvService, `wfaFormDraft/publish`)

  return routing.httpClient.post<void>(url, { wfaForm }, httpOptions).pipe(
    catchError(error => {
      routing.wfaEnvService.logger.error(`[publishDraft]: Publishing failed`, {
        error,
        form: wfaForm,
        url,
      })
      throw new FormPublishError(error?.message ?? 'Form publication failed')
    }),
  )
}

export function publishDraftUsingForm(
  maybeWfaForm: Nullable<WfaForm>,
  routing: Routing<AdisEditorEnv>,
): Observable<void> {
  if (isNil(maybeWfaForm)) {
    throw new NilError('[publishDraft]: Form is null or undefined')
  }

  const wfaForm = updateMetadata(maybeWfaForm)
  customizeCreatorJson(wfaForm)

  return publishForm(wfaForm, routing).pipe(
    take(1),
    timeout(10000),
    catchError(error => {
      console.error('[publishDraftUsingForm]: Publishing failed:', error)
      throw error
    }),
    tap(() => navigateToEditorOrigin(routing)),
  )
}
