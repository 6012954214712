import { assertType, getCurrentDateTime } from '@arzt-direkt/wfa-generic-utils'

import { WfaFormId } from '../interfaces-autogen-from-joi'
import { WfaFormIdJoi } from '../joi/wfa-form-id.joi'

/**
 * Converts input string to a valid slug format for WfaFormIds
 * @param input - Raw string to be converted
 * @param fallbackDate - Date to use for fallback ID generation
 * @returns Formatted string meeting WfaFormId requirements
 */
function createSlugFromInput(input: string, fallbackDate: Date): string {
  const specialChars: Record<string, string> = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    ß: 'ss',
  }
  let result = input.toLowerCase()
  result = result
    // Normalize multiple spaces to single space
    .replace(/\s+/g, ' ')
    // Convert spaces to hyphens
    .replace(/\s/g, '-')
    // Convert German special chars to standard ASCII
    .replace(/[äöüß]/g, match => specialChars[match] || match)
    // Convert underscores to hyphens
    .replace(/_/g, '-')
    // Remove all non-alphanumeric chars except dots and hyphens
    .replace(/[^a-z0-9.-]/g, '')
    // Remove leading/trailing dots and hyphens
    .replace(/^[.-]+|[.-]+$/g, '')

  // Ensure string starts with alphanumeric by prepending 'a' if needed
  if (!/^[a-z0-9]/.test(result)) {
    result = 'a' + result
  }

  // Use default fallback if the result is too short
  return result.length < 2 ? `form-${getCurrentDateTime(fallbackDate)}` : result
}

/**
 * Converts a string to a valid WfaFormId, validating against WfaFormIdJoi schema
 * @param input - String to convert to WfaFormId
 * @param date - Optional date for fallback ID generation
 * @returns Valid WfaFormId
 */
export function convertToWfaFormId(
  input: string,
  date: Date = new Date(),
): WfaFormId {
  try {
    return assertType(WfaFormIdJoi, input)
  } catch {
    return createSlugFromInput(input, date)
  }
}
