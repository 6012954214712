import { MatDialog } from '@angular/material/dialog'
import {
  PraxisId,
  updateMetadata,
  repairAndAssertWfaForm,
  WfaForm,
} from '@arzt-direkt/wfa-definitions'
import { Nullable, isNil } from '@arzt-direkt/wfa-generic-utils'

import { openUnlockFormUploadDialog } from './upload-error.component'

/**
 * mutates wfaForm
 */
export function maybeImportWfaForm(
  event: any,
  matDialog: MatDialog,
  praxisId: Nullable<PraxisId>,
  wfaForm: Nullable<WfaForm>,
  callback: (_wfaForm: Nullable<WfaForm>) => void, // perform after successfull import
) {
  if (isNil(praxisId)) {
    console.error('[importJson]: praxisId is undefined')
    return undefined
  }

  const file: File = event?.target?.files[0]
  const reader = new FileReader()

  reader.onload = (_e: any) => {
    if (typeof reader.result === 'string') {
      const withoutMetadata = {
        ...JSON.parse(reader.result),
        praxisId,
        instance: praxisId,
      }
      const form = updateMetadata(withoutMetadata)
      try {
        repairAndAssertWfaForm(form)
        const { permalink: _, ...withoutPermalink } = form
        wfaForm = withoutPermalink
        callback(wfaForm)
        return
      } catch (err: unknown) {
        openUnlockFormUploadDialog(matDialog, err as Error)
        console.debug(
          `[importJson]: could not validate uploaded form, got error: ${(err as Error).message}`,
          form,
        )
        wfaForm = undefined
        return
      }
    }
    wfaForm = undefined
    return
  }

  reader.readAsText(file)
}
