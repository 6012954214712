import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { Route, Router } from '@angular/router'
import { SafePipe } from '../test-webview-viewer/safe.pipe'
import { getWfaFrontendBaseUrl } from './../../../environments/get-wfa-url'
import { WfaEnvService } from '../../../environments/wfa-env.service'

@Component({
  selector: 'app-e2e-webview-viewer',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './e2e-webview-viewer.component.html',
  styleUrls: ['./e2e-webview-viewer.component.scss'],
})
export class E2eWebviewViewerComponent implements AfterViewInit {
  @ViewChild('twvIframe', { static: false }) twvIframe!: ElementRef
  url: string
  currentFormNo = 0

  constructor(
    private router: Router,
    private wfaEnv: WfaEnvService,
  ) {
    const webformsRootUrl = getWfaFrontendBaseUrl(this.wfaEnv)
    this.url = `${webformsRootUrl}/webview-viewer`
    console.log(`[e2eWebviewViewer/constructor]: set this.url to ${this.url}`)
  }

  ngAfterViewInit() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (iframeOnInitComplete(event)) {
        console.log(
          '[e2eWebviewViewer/AfterViewInit]: iframe on init complete.',
        )
      }
    })
  }
}

function iframeOnInitComplete(event: MessageEvent): boolean {
  return event.data?.action === 'webview-viewer: OnInit complete'
}

export const e2eWebviewViewerRoute: Route = {
  path: 'e2e-webview-viewer',
  component: E2eWebviewViewerComponent,
}
