import { Clipboard } from '@angular/cdk/clipboard'
import { repairAndAssertWfaForm } from '@arzt-direkt/wfa-definitions'
import { remove } from '@arzt-direkt/wfa-generic-utils'
import { Action } from 'survey-core'
import { SurveyCreatorModel } from 'survey-creator-core'

import { WfaEnv } from './../../../../../environments/wfa-env'
import { customizeCreatorJson } from './../../creator-json-customization/customize-creator-json'
import { addToolboxAction } from './add-toolbox-action'

const formValidationWarningId = 'toolbox-action-form-validation-warning'
export function addFormValidationWarning(
  creator: SurveyCreatorModel,
  wfaEnv: WfaEnv,
  clipboard: Clipboard,
) {
  const index = creator.toolbarItems.findIndex(
    item => item.id === formValidationWarningId,
  )

  if (index === -1) {
    addToolboxAction(creator, {
      id: formValidationWarningId,
      iconName: 'icon-warning',
      title: [
        'Das Formular weist Unstimmigkeiten auf.',
        'Klicken Sie hier, um die Fehlerdetails in die Zwischenablage zu kopieren',
      ].join(' '),
      action: () => {
        const rawJson = creator.JSON
        customizeCreatorJson(rawJson)

        try {
          repairAndAssertWfaForm(rawJson)
        } catch (error: any) {
          console.log('[formValidationWarning]', error.message)
          wfaEnv.notification.warn(
            `WFA.NOTIFICATION.SAVE-TO-STORE-AND-DB.FORM-VALIDATION-WARNING`,
          )
          const details = {
            message: error.message,
            wfaForm: rawJson,
          }
          const errorDetails = JSON.stringify(details, null, 2)
          clipboard.copy(errorDetails)
        }
      },
      index: 3,
    })
  }
}

export function removeFormValidationWarning(creator: SurveyCreatorModel) {
  remove(
    creator.toolbarItems,
    (item: Action) => item.id === formValidationWarningId,
  )
}
