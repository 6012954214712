import { cloneDeep } from '@arzt-direkt/wfa-generic-utils'
import { ItemValue, Question } from 'survey-core'
import { ModifiedEvent, SurveyCreatorModel } from 'survey-creator-core'

/**
 * Registers an event handler to update a question’s default value when an item value (choice, row or column) is deleted.
 * No other event type other than the OnModifiedEvent is triggered when an item value is deleted.
 */
export function updatePropertiesOnItemValueDeletion(
  creator: SurveyCreatorModel,
): void {
  creator.onModified.add((creator, options) => {
    const { name } = options as unknown as { name: string }

    if (isItemValueDeletionEvent(name, options))
      updateDefaultValueOnItemValueDeletion(name, options)
  })
}

function isItemValueDeletionEvent(name: string, options: ModifiedEvent) {
  if (options.type !== 'PROPERTY_CHANGED') return false
  if (!['choices', 'rows', 'columns'].includes(name)) return false
  if (!Array.isArray(options.newValue) || options.newValue.length !== 0)
    return false

  return true
}

function updateDefaultValueOnItemValueDeletion(
  name: string,
  options: ModifiedEvent,
): void {
  const oldItemValueArray = options.oldValue as unknown as ItemValue[]
  const oldItemValue = oldItemValueArray.at(0)
  const question = options.target as unknown as Question
  const defaultValue = question.defaultValue

  if (!defaultValue || !oldItemValue) return

  if (Array.isArray(defaultValue)) {
    updateArrayDefaultValue(question, oldItemValue)
  } else if (typeof defaultValue === 'string') {
    updateStringDefaultValue(question, oldItemValue)
  } else if (typeof defaultValue === 'object') {
    updateObjectDefaultValue(question, oldItemValue, name)
  }
}

function updateArrayDefaultValue(
  question: Question,
  oldItemValue: ItemValue,
): void {
  if (
    Array.isArray(question.defaultValue) &&
    question.defaultValue.includes(oldItemValue.value)
  ) {
    question.defaultValue = question.defaultValue.filter(
      itemValue => itemValue !== oldItemValue.value,
    )
  }
}

function updateStringDefaultValue(
  question: Question,
  oldItemValue: ItemValue,
): void {
  if (question.defaultValue === oldItemValue.value) {
    question.defaultValue = null
  }
}

function updateObjectDefaultValue(
  question: Question,
  oldItemValue: ItemValue,
  name: string,
): void {
  if (name === 'rows') {
    updateDefaultValueForRows(question, oldItemValue)
  } else if (name === 'columns') {
    updateDefaultValueForColumns(question, oldItemValue)
  }
}

function updateDefaultValueForRows(
  question: Question,
  oldItemValue: ItemValue,
): void {
  const defaultValueObj = cloneDeep(question.defaultValue) as Record<
    string,
    any
  >
  delete defaultValueObj[oldItemValue.value]
  question.defaultValue = defaultValueObj
}

function updateDefaultValueForColumns(
  question: Question,
  oldItemValue: ItemValue,
): void {
  const defaultValueObj = cloneDeep(question.defaultValue) as Record<
    string,
    any
  >
  Object.keys(defaultValueObj).forEach(key => {
    const rowVal = defaultValueObj[key]

    if (Array.isArray(rowVal)) {
      const filtered = rowVal.filter(
        (col: string) => col !== oldItemValue.value,
      )
      if (filtered.length) {
        defaultValueObj[key] = filtered
      } else {
        delete defaultValueObj[key]
      }
    } else if (typeof rowVal === 'string' && rowVal === oldItemValue.value) {
      delete defaultValueObj[key]
    }
  })
  question.defaultValue = defaultValueObj
}
