import { Nullable } from '../not-lodash/is-nil'

/**
 * Returns first non-null/undefined value from array, guaranteed to find one
 * @param values Tuple with at least one non-nullable value at the end
 * @returns First non-null/undefined value in array
 * @throws Never - Last value is guaranteed non-nullable by type constraint
 * @example
 * firstNonNullable([null, undefined, 'abc']) // Returns 'abc'
 * firstNonNullable([null, 'xyz', 'abc']) // Returns 'xyz'
 */
export function findFirstNonNil<T>(values: [...Nullable<T>[], T]): T {
  // We know the last value is non-null, so we can use it as fallback
  return values.find(value => value != null) as T
}
