import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly DEFAULT_SNACKBAR_OPTIONS: MatSnackBarConfig = {
    duration: 10000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  }

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}

  private show(
    message: string | string[],
    panelClass: string[],
    action: string = 'WFA.NOTIFICATION.CLOSE',
  ): void {
    const translatedMessage = Array.isArray(message)
      ? message.map((m) => this.translateService.instant(m)).join(' ')
      : this.translateService.instant(message)
    const translatedAction = this.translateService.instant(action)

    this.snackBar.open(translatedMessage, translatedAction, {
      ...this.DEFAULT_SNACKBAR_OPTIONS,
      panelClass: ['c-snackbar', ...panelClass],
    })
  }

  log(message: string | string[]): void {
    this.show(message, ['c-snackbar--log'])
  }

  warn(message: string | string[]): void {
    this.show(message, ['c-snackbar--warn'])
  }

  error(message: string | string[]): void {
    this.show(message, ['c-snackbar--error'])
  }
}
