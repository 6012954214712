import { AdLoggerService } from '@a-d/logging/ad-logger.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { PasswordDialogComponent } from './auth/password-dialog/password-dialog.component';
import { CookiesService } from './dsgvo/cookies.service';
import { InstanceStatus } from './entities/Instance.entity';
import { InstanceService } from './instance/instance.service';

@Injectable()
export class SessionRecoveryInterceptor implements HttpInterceptor {
  constructor(
    private adLoggerService: AdLoggerService,
    private http: HttpClient,
    private dialog: MatDialog,
    private authService: AuthService,
    private instanceService: InstanceService,
    private cookieService: CookiesService
  ) { }

  private isRefreshing = new BehaviorSubject<boolean>(false);
  private passwordDialogRef: MatDialogRef<PasswordDialogComponent> | undefined;
  private cookieHopefullyReadyTimeMs = 700;

  private appointmentRoutesToSecure: string[] = [
    "appointment-category/update",
    "appointment-category/delete",
    "appointment-type/attachment",
    "appointment-type/delete",
    "appointment-type/delete-many",
    "appointment-type/update",
    "appointment-type/update-many",
    "appointment-type/update-series-type",
    "betriebsstaetten/update",
    "email/preview",
    "link/create",
    "link/update",
    "link/delete",
    "otkuser/update",
    "otkuser/allSettings",
    'wfb/app/copyDynamicWfaForm',
    "wfb/app/copyStaticWfaForms",
    "wfb/app/updateWfaFormStatus",
    "wfb/app/updateWfaFormPermalink",
    "wfb/app/wfaFormDraft/create",
    "wfb/app/wfaFormDraft/delete",
    "wfb/app/wfaFormDraft/publish",
    "wfb/app/wfaFormDraft/update",
  ]

  private timeoutObservable = new Observable<null>(observer => {
    setTimeout(() => {
      observer.next(null);
      observer.complete();
    }, this.cookieHopefullyReadyTimeMs)
  })

  private isTokenError(error: HttpErrorResponse): boolean {
    return (error.status
      && (error.status === 401 || error.status === 403)
      && error.error);
  }

  private openPasswordDialog(user: any): Observable<boolean> {
    if (!this.passwordDialogRef) this.passwordDialogRef = this.dialog.open(PasswordDialogComponent, this.getPasswordDialogConfig(user));
    return of(null).pipe(
      mergeMap(() => this.passwordDialogRef.afterClosed()),
      mergeMap((result) => {
        this.passwordDialogRef = undefined;
        return of(result)
      })
    )
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    const csrfHeaderName = 'X-CSRF-TOKEN';
    const csrfToken = this.cookieService.csrfToken;
    let clonedRequest = request.clone();
    if (csrfToken && !url?.includes('https://geocoding.zollsoft.de')) {
      clonedRequest = request.clone({ headers: request.headers.set(csrfHeaderName, csrfToken) });
    }
    if (url.endsWith('/login') || url.endsWith('/initialize') || url.endsWith('/log-download')) {
      return next.handle(clonedRequest);
    }
    if (this.appointmentRoutesToSecure.some(route => url.includes(route))) {
      const instanceId = this.instanceService.activeInstance?._id;
      const pwd = this.authService.passwordForOtk;
      if (!instanceId || !pwd) return next.handle(clonedRequest);
      return of(null).pipe(
        mergeMap(() => this.authService.getUser()),
        mergeMap((user) => {
          if (!user) return next.handle(clonedRequest);
          if (user.isInstance && user.status !== InstanceStatus.Enabled) return next.handle(clonedRequest);
          // const clonedRequest = request.clone();
          const isAdmin = user.isAdmin;
          const appExtraInstanceId = isAdmin ? user._id : instanceId;
          Object.assign(clonedRequest.body, {
            appExtraInstanceId: appExtraInstanceId,
            appExtraPwd: pwd,
            appExtraIsAdmin: isAdmin
          });
          return next.handle(clonedRequest)
        }),
        catchError((error) => {
          this.adLoggerService.error(error);
          return next.handle(clonedRequest)
        })
      )
    }
    if (url.endsWith('/auth/user')) {
      // api/auth/user is only called by the auth-guards if there is no authService.user, i.e. should only be called on first login and refresh
      return next.handle(clonedRequest).pipe(
        mergeMap((event: HttpEvent<{ token: string, user: any }>) => {
          if (event instanceof HttpResponse) {
            // there is only a httpResponse in case that there is a valid auth token - then we want to trigger the login-logic
            // of the login dialog in order to reinitialize crypto
            const user = event.body.user;
            return this.openPasswordDialog(user).pipe(
              mergeMap((authenticated: boolean) => {
                if (authenticated) return of(event)
                return throwError(() => new Error("Authentication failed"))
              })
            )
          }
          return of(event)
        })
      )
    }
    return next.handle(clonedRequest).pipe(
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse && [401, 403].includes(error.status))) {
          console.log("intercepted error for which there is no special handling - just throw...")
          return throwError(() => error);
        }

        if (clonedRequest.url.includes('/token/refresh')) {
          console.log("here we should only be if authentication failed or no user found...")
          return throwError(() => error)
        }
        if (this.isRefreshing.value) {
          console.log("refresh already being executed - wait for the logic to be completed...")
          return this.isRefreshing.pipe(
            filter((refreshing) => !refreshing),
            take(1),
            mergeMap(() => next.handle(clonedRequest))
          )
        }
        if (!this.isTokenError(error)) {
          this.adLoggerService.error(error, "intercepted '401 or 403'-error which is not because of token expired")
          return throwError(() => error);
        }
        console.log("intercepted token error, trying to refresh...")
        this.isRefreshing.next(true);
        return this.http.post('/api/token/refresh', {}).pipe(
          mergeMap(() => this.timeoutObservable), // include timeout as fix for "cookie not immediately ready" bug
          mergeMap(() => {
            this.isRefreshing.next(false);
            console.log("refreshing successfull, executing original request...")
            return next.handle(clonedRequest)
          }),
          catchError((innerError) => {
            if (!(innerError instanceof HttpErrorResponse && [401, 403].includes(innerError.status))) {
              console.log("intercepted error for which there is no special handling - just throw...")
              return throwError(() => error);
            }
            console.log("refreshing failed, opening password dialog (if user...)")
            const user = this.authService.user;
            if (user && (user.isArzt || user.isAdmin || user.isInstance)) {
              return of(null).pipe(
                mergeMap(() => this.openPasswordDialog(user)),
                mergeMap((authenticated: boolean) => {
                  this.isRefreshing.next(false);
                  if (authenticated) {
                    console.log("successfully authenticated, executing original request...")
                    return next.handle(clonedRequest)
                  }
                  // if !authenticated, password-dialog triggers this.authService.logout() which then reroutes to login-page
                  console.log("authentication failed, rethrowing error")
                  return throwError(() => innerError)
                }),
                catchError((pwdDialogError) => {
                  this.adLoggerService.error(pwdDialogError)
                  this.adLoggerService.error("Error while opening password dialog. Rerouting to login...");
                  this.isRefreshing.next(false)
                  this.authService.logout();
                  return of(null)
                })
              )
            }
            console.log("no user found, rethrowing error")
            this.isRefreshing.next(false);
            return throwError(() => innerError)
          })
        )
      })
    )
  }

  private getPasswordDialogConfig(user: any): MatDialogConfig {
    return {
      data: {
        user: user,
        logoutOnError: true,
        login: true
      },
      disableClose: true,
      backdropClass: 'c-dialog-blue-backdrop'
    }
  }
}
